import { ELECTION_API_REDESIGN_DATE } from "@/src/elections/business-layer/config"
import {
  CandidateCampaignInfo,
  MapTemplateProps,
  Race
} from "@/src/elections/business-layer/types"
import { TableHeaderV2 } from "@/src/elections/components/Table/TableV2"
import {
  ElectionSources2023,
  PresidentCollationLevel
} from "@/src/elections/data-layer/types"
import { FeatureCollection } from "geojson"

export const GENDER: Record<"m" | "f", "Male" | "Female"> = {
  m: "Male",
  f: "Female"
}
export const REGION = {
  NC: "North-Central",
  NW: "North-West",
  NE: "North-East",
  SS: "South-South",
  SW: "South-West",
  SE: "South-East"
}

export const RACE_TO_NAME: Record<string, string> = {
  president: "President",
  governor: "Governor",
  senate: "Senate",
  house: "House of Representatives",
  state_house: "State Houses of Assembly",
  "house-of-representatives": "House of Representatives",
  "state-houses-of-assembly": "State Houses of Assembly"
}

export const RACE_ADJECTIVE: Record<string, string> = {
  president: "Presidential",
  governor: "Governorship",
  senate: "Senatorial district",
  house: "House of Representatives",
  state_house: "State Houses of Assembly",
  "house-of-representatives": "House of Representatives",
  "state-houses-of-assembly": "State Houses of Assembly"
}

export const DROPDOWN_NAME_OVERRIDE: Record<string, string> = {
  FCT: "FCT"
}

export const MY_ELECTIONS_COPY: Record<string, string> = {
  label: "My Elections",
  description:
    "Choose elections and we’ll send you the latest predictions and results"
}
export const ELECTIONS_BANNER = "elections-banner"
export const ENERGY_PRICE_TRACKER = "energy-price-tracker"
export const ELECTION_PREFERENCE_LOCAL_KEY = "election-preference"
export const HISTORICAL_RESULTS = "historical-results"
export const HISTORICAL_RESULTS_START_YEAR = parseInt(
  process.env.NEXT_PUBLIC_TIMELINE_START_YEAR || "2015"
)
export const DEFAULT_ELECTION_YEAR =
  process.env.NEXT_PUBLIC_DEFAULT_ELECTION_YEAR || "2023"

export const ELECTION_PARTY_COLOR_OTHERS = "#4B5563"
export const ELECTION_NO_RESULT_COLOR = "#E5E7EB"

export const ELECTION_TICKAROO_CLIENT_ID =
  process.env.ELECTION_TICKAROO_CLIENT_ID
export const ELECTION_TICKAROO_LIVE_GOVERNORSHIP_BLOG_ID = process.env
  .ELECTION_TICKAROO_LIVE_GOVERNORSHIP_BLOG_ID as string
export const ELECTION_TICKAROO_LIVE_BLOG_ID = process.env
  .ELECTION_TICKAROO_LIVE_BLOG_ID as string
export const ELECTION_TICKAROO_THEME_ID =
  process.env.ELECTION_TICKAROO_THEME_ID || "lct_63ce24dbd92fbd09e112964f"
export const ELECTION_TICKAROO_POST_LIMIT =
  process.env.ELECTION_TICKAROO_POST_LIMIT || 10

export const ELECTION_TICKAROO_LIVE_PRESIDENT_BLOG = process.env
  .NEXT_PUBLIC_ELECTION_TICKAROO_LIVE_PRESIDENT_BLOG as string
export const ELECTION_TICKAROO_LIVE_GOVERNORSHIP_BLOG = process.env
  .NEXT_PUBLIC_ELECTION_TICKAROO_LIVE_GOVERNORSHIP_BLOG as string

export const PRESIDENT_COLLATION_LEVEL: PresidentCollationLevel =
  (process.env.PRESIDENT_COLLATION_LEVEL as PresidentCollationLevel) ||
  PresidentCollationLevel.LGA

export const DEFAULT_LEGEND = [
  { name: "APC", color: "#60A5FA" },
  { name: "LP", color: "#F05042" },
  { name: "PDP", color: "#109373" },
  { name: "NNPP", color: "#264ABC" },
  { name: "Others", color: ELECTION_PARTY_COLOR_OTHERS },
  { name: "Awaiting", color: ELECTION_NO_RESULT_COLOR }
]

export const HISTORICAL_RESULT_CANDIDATE_TABLE_HEADERS: Array<
  TableHeaderV2<CandidateCampaignInfo>
> = [
  {
    name: "Candidate",
    key: "name",
    id: "slug"
  },
  {
    name: "Party",
    key: "partyCode",
    id: "partyCode"
  },
  {
    name: "Votes Margin",
    key: "votesMargin" as any, // virtual property
    id: "votes"
  },
  {
    name: "Votes",
    key: "votes",
    id: "votes"
  },
  {
    name: "Percent",
    key: "votePercentageFormatted",
    id: "votePercentageFormatted"
  }
]

/**
 * A map of race key to race url slug
 */
export const RACE_TO_URL_SLUG_HASH_MAP: Record<Race, string> = {
  president: "president",
  governor: "governor",
  senate: "senate",
  house: "house-of-representatives",
  state_house: "state-houses-of-assembly"
}

/**
 * Swap RACE_URL_SLUG_HASH_MAP key: value => value: key
 * @see RACE_TO_URL_SLUG_HASH_MAP
 */
export const URL_SLUG_TO_RACE_HASH_MAP: Record<string, Race> = Object.entries(
  RACE_TO_URL_SLUG_HASH_MAP
).reduce((hash, record) => ({ ...hash, [record[1]]: record[0] }), {})

/**
 * Get race data source
 * return "undefined" for election year earlier than USE_OF_LGA_DATE.
 * Use of election source begin the same year as USE_OF_LGA_DATE.
 */
export const GET_RACE_SOURCE: (
  year: string,
  race: Race,
  isStateLevel: boolean
) => ElectionSources2023 | undefined = (year, race, isStateLevel) => {
  if (parseInt(year) < new Date(ELECTION_API_REDESIGN_DATE).getUTCFullYear())
    return

  switch (race) {
    case "president":
      if (isStateLevel) return ElectionSources2023.TRACKA
      return ElectionSources2023.TV
    case "governor":
      if (isStateLevel) return ElectionSources2023.STATE
      return ElectionSources2023.STATE
    case "senate":
      if (isStateLevel) return ElectionSources2023.TRACKA
      return ElectionSources2023.TRACKA
    case "house":
      if (isStateLevel) return ElectionSources2023.TRACKA
      return ElectionSources2023.TRACKA
    case "state_house":
      if (isStateLevel) return ElectionSources2023.CONSTITUENCY
      return ElectionSources2023.CONSTITUENCY
    default:
  }
}

export const GET_MAP_PROPS: (
  year: string,
  race: Race,
  isStateLevel: boolean
) => Promise<Omit<MapTemplateProps, "regions">> = async (
  year,
  race,
  isStateLevel
) => {
  switch (race) {
    case "president":
      if (isStateLevel)
        return parseInt(year) >=
          new Date(ELECTION_API_REDESIGN_DATE).getUTCFullYear()
          ? {
              avoidCollisions: true,
              mapFeatures: (await import("../data-layer/geojson/lgas.json"))
                .default as FeatureCollection
            }
          : {
              avoidCollisions: false,
              mapFeatures: (await import("../data-layer/geojson/governor.json"))
                .default as FeatureCollection
            }

      return {
        avoidCollisions: false,
        mapFeatures: (await import("../data-layer/geojson/governor.json"))
          .default as FeatureCollection
      }

    case "governor":
      if (isStateLevel)
        return {
          avoidCollisions: true,
          mapFeatures: (await import("../data-layer/geojson/lgas.json"))
            .default as FeatureCollection
        }
      return {
        avoidCollisions: false,
        hideLosersWhenDataIsIncomplete: true,
        mapFeatures: (await import("../data-layer/geojson/governor.json"))
          .default as FeatureCollection
      }

    case "senate":
      if (isStateLevel)
        return {
          mapFeatures: (await import("../data-layer/geojson/senate.json"))
            .default as FeatureCollection
        }
      return {
        stateBoundaries: (
          await import("../data-layer/geojson/senate-state-boundaries.json")
        ).default as FeatureCollection,
        mapFeatures: (await import("../data-layer/geojson/senate.json"))
          .default as FeatureCollection
      }

    case "house":
      if (isStateLevel)
        return {
          appendStateName: true,
          mapFeatures: (
            await import("../data-layer/geojson/house-of-representatives.json")
          ).default as FeatureCollection
        }
      return {
        appendStateName: true,
        stateBoundaries: (
          await import(
            "../data-layer/geojson/house-of-representatives-state-boundaries.json"
          )
        ).default as FeatureCollection,
        mapFeatures: (
          await import("../data-layer/geojson/house-of-representatives.json")
        ).default as FeatureCollection
      }

    case "state_house":
      if (isStateLevel)
        return {
          appendStateName: true,
          mapFeatures: (
            await import("../data-layer/geojson/state-houses-of-assembly.json")
          ).default as FeatureCollection
        }
      return {
        appendStateName: true,
        stateBoundaries: (
          await import(
            "../data-layer/geojson/state-houses-of-assembly-state-boundaries.json"
          )
        ).default as FeatureCollection,
        mapFeatures: (
          await import("../data-layer/geojson/state-houses-of-assembly.json")
        ).default as FeatureCollection
      }
    default:
      return { mapFeatures: { type: "FeatureCollection", features: [] } }
  }
}
