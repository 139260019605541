import {
  ANALYTICS_PLATFORM_TYPE,
  BASE_URL,
  MIXPANEL_ENABLE_DEBUG,
  MIXPANEL_IGNORE_DNT
} from "@/src/common/config"
import { snakeCaseObjectProperty } from "@/src/common/utils"
import mixpanel, {
  Config as MixpanelConfig,
  OverridedMixpanel
} from "mixpanel-browser"
import { title } from "process"

import {
  DEFAULT_ANALYTICS_COLLECTORS,
  GA_TRACKING_ID,
  NEXT_PUBLIC_MIXPANEL_TOKEN,
  V2_FEATURE_TOGGLES
} from "../../constants/configs"
import { SessionUser } from "../../core/models/auth"

export type AnalyticsCollector = "mixpanel" | "ga"
type TaggedUserProps = {
  memberStatus: string
  userType: string
  userId?: string
  email?: string
  name?: string
}

const DEFAULT_COLLECTORS: AnalyticsCollector[] =
  DEFAULT_ANALYTICS_COLLECTORS as AnalyticsCollector[]

const DEFAULT_MIXPANEL_CONFIG = {
  debug: MIXPANEL_ENABLE_DEBUG,
  ignore_dnt: MIXPANEL_IGNORE_DNT,
  autotrack: false,
  api_host: V2_FEATURE_TOGGLES.has("mixpanel-proxy")
    ? BASE_URL + "/mxp"
    : undefined
}

const initializeMixpanel = (
  token: string = NEXT_PUBLIC_MIXPANEL_TOKEN,
  config: Partial<MixpanelConfig> = {},
  mixpanelInstance: OverridedMixpanel = mixpanel
) => {
  if (!token) return
  try {
    mixpanelInstance.init(token, {
      ...DEFAULT_MIXPANEL_CONFIG,
      ...config
    })
  } catch (e) {
    console.warn(e)
    // pass for now. Later turn into browser log
  }
}

export type EventTrackOptions = {
  eventProperties?: Record<
    string,
    string | number | boolean | Record<string, string | number | boolean>
  >
  pageView?: { title: string }
  ignoreGA?: boolean
}

export class AnalyticsAdapter {
  constructor(
    private readonly allowedCollectors: Set<AnalyticsCollector> = new Set(
      DEFAULT_COLLECTORS
    ),
    private readonly mixpanel?: OverridedMixpanel
  ) {}

  public static create(
    allowedCollectors: Set<AnalyticsCollector> = new Set(DEFAULT_COLLECTORS),
    mixpanelToken: string = NEXT_PUBLIC_MIXPANEL_TOKEN,
    mixpanelConfig: Partial<MixpanelConfig> = {},
    mixpanelInstance: OverridedMixpanel = mixpanel
  ) {
    if (allowedCollectors.has("mixpanel")) {
      initializeMixpanel(mixpanelToken, mixpanelConfig, mixpanelInstance)
    }
    return new AnalyticsAdapter(allowedCollectors, mixpanelInstance)
  }

  public tagCurrentUser(user: SessionUser) {
    let memberStatus = "free" // Default
    const userId = user.data?.sub
    const email = user.data?.email
    const name = user.data?.nickname
    const userType = user.userType === "anonymous" ? "Visitor" : "Member"
    const licenceCategory = user.data?.corporate_subscriber_licence

    if (user.userType === "subscriber" && !licenceCategory) {
      memberStatus = "individual_subscriber"
    }

    if (user.userType === "subscriber" && licenceCategory) {
      memberStatus = "corporate_user"
    }

    const tagProps = {
      memberStatus,
      userType,
      userId,
      email,
      name,
      licenceCategory: user.data?.corporate_subscriber_licence ?? "",
      corporateSubscriber: user.data?.corporate_subscriber_name ?? "",
      corporateId: user.data?.corporate_subscriber_id ?? ""
    }

    this.tagOnGA(tagProps)
    this.tagOnMixpanel(tagProps)
  }

  private tagOnGA({ memberStatus, userType, userId }: TaggedUserProps) {
    try {
      window?.gtag?.("config", GA_TRACKING_ID, {
        custom_map: {
          dimension2: "user_type",
          dimension3: "user_id",
          dimension5: "member_status"
        },
        user_id: userId,
        user_type: userType,
        member_status: memberStatus
      })

      // GTM focused
      ;(window as any)?.dataLayer?.push(
        snakeCaseObjectProperty({
          userId,
          userType,
          memberStatus
        })
      )
    } catch (error) {
      console.warn(error)
    }
  }

  private tagOnMixpanel({
    userId,
    email,
    name,
    ...restTaggedUserProps
  }: TaggedUserProps) {
    try {
      if (!userId) {
        // no user ID to identify user
        return
      }

      this.mixpanel?.identify(userId)
      this.mixpanel?.people.set({
        ...snakeCaseObjectProperty(restTaggedUserProps),
        $email: email,
        $name: name
      })
    } catch (error) {
      console.warn(error)
    }
  }

  public track(
    eventName: string,
    eventCategory: string,
    options: EventTrackOptions = {}
  ) {
    if (process.env.NODE_ENV === "test") return
    const { eventProperties = {}, pageView, ignoreGA } = options
    const properties = {
      ...eventProperties,
      platform: ANALYTICS_PLATFORM_TYPE
    }

    if (this.allowedCollectors.has("ga") && !ignoreGA) {
      this.trackGA(pageView, eventName, properties, eventCategory)
    }

    if (this.allowedCollectors.has("mixpanel")) {
      this.trackMixpanel(eventName, properties)
    }
  }

  public reset() {
    this.mixpanel?.reset()
  }

  public setPersistentGlobalEventProps(props: Record<string, any>) {
    this.mixpanel?.register(props)
  }

  public tagVisitor({ memberStatus }: { memberStatus: string }) {
    this.mixpanel?.people.set({
      memberStatus
    })
  }

  private trackMixpanel(eventName: string, eventProperties: object) {
    try {
      this.mixpanel?.track(eventName, { ...eventProperties })
    } catch (error) {
      console.warn("Mixpanel track failed")
    }
  }

  private trackGA(
    pageView: { title: string } | undefined,
    eventName: string,
    eventProperties: Record<string, string | number | boolean>,
    eventCategory: string
  ) {
    try {
      if (pageView) {
        window?.gtag("config", GA_TRACKING_ID, {
          page_path: window.location.pathname,
          page_title: title
        })
      } else {
        window?.gtag("event", eventName, {
          ...eventProperties,
          event_category: eventCategory
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }
}
