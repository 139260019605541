import { pages } from "./pageLinks"

export const signin = {
  title: "Sign in to your account",
  dontHaveAnAccount: "Don't have an account?",
  signupLinkLabel: "Request Demo",
  mobile: "Register for free",
  googleBtnLabel: "Login with Google",
  appleBtnLabel: "Login with Apple",
  nudgeGoogleAuth: "Start reading faster with Google sign in"
}

export const resetPassword = {
  title: "Reset Password",
  subtitle: "Please enter your email to reset your password",
  dontHaveAnAccount: "Don't have an account?",
  signupLinkLabel: "Sign up"
}

export const confirmPasswordReset = {
  title: "Create a New Password",
  subtitle: "Please enter a new password for your account",
  doYouHaveAnAccount: "Do you have an account?",
  signinLinkLabel: "Sign in"
}

export const links = {
  register: `${pages.signup}?intent=freemium`,
  subscribe: pages.subscriptions,
  landingPage: pages.landingPage,
  home: pages.home
}
