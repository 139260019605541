import { IconProps } from "@/src/sb/business-layer/types"

const MobileNavArrow = (props: IconProps) => (
  <svg
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 3.75V5H14.1187L3.75 15.3687L4.63125 16.25L15 5.88125V13.75H16.25V3.75H6.25Z"
      fill={props.color ?? "currentColor"}
    />
  </svg>
)

export default MobileNavArrow
