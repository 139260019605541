import { SearchInputProps } from "@/src/sb/components/Input/SearchInput"
import { SEOProps } from "@/src/sb/components/SEO/settings"
import { accounts } from "@/src/sb/constants/copy/navItems"
import { Article, PaginatedResponse } from "@/src/sb/core"
import { ReactNode, UIEvent } from "react"

export type Currency = "USD" | "NGN"

export type IconProps = {
  size?: number
  scale?: number
  width?: number
  height?: number
  color?: string
  minX?: number
  minY?: number
}

export type MembershipPlan = {
  name: string
  description: string
  code: string
  subtitle: string
  amount: number
  htmlCurrencyCode: string
  currencyCode: Currency
  intervalId: number
  intervalName: string
  bankTransferEnabled: boolean
  bestValue?: boolean
}

export type Features = Record<
  string,
  string | number | boolean | Record<string, any>
>

export type LayoutProps = {
  seo?: SEOProps
  navigationMenu?: NavigationMenuItem[]
  className?: string
  showNavBar?: boolean
  showFooter?: boolean
  setNextQueryParam?: boolean
  features?: Features
}

export type LayoutPropsV3 = Omit<LayoutProps, "navigationMenu"> & {
  topBarAddon?: ReactNode
  asideFooter?: ReactNode
  onScroll?: (e: UIEvent) => void
  navigationMenu?: Record<string, SidebarMenuItemV2[]> | SidebarMenuItem[]
  searchInputProps?: SearchInputProps & {
    searchQuery: string
    tags?: Array<string>
  }
  onFilterToggle?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export type SubscriptionsPageData = {
  plans: Record<Currency, MembershipPlan[]>
  navigationMenu?: NavigationMenuItem[]
}

export type ArticlePageData<L = LayoutPropsV3> = {
  article: Report
  recommendedReports: ReportSummary[]
  articleIsTruncated: boolean
  layoutProps?: L
}

export type SearchPageData<L = LayoutPropsV3> = {
  searchResults: PaginatedResponse<Article>
  layoutProps?: L
}

export type DatasetTag = Tag & { categoryName: string; core: boolean }

export type Dataset = {
  id: number
  name: string
  description: string
  image: string | null
  slug: string
  category: string
  groupName: string
  groupSlug: string
  bucketName: string | null
  bucketSlug: string | null
  pubDate: string
  tags: Array<DatasetTag>
  mainTag: DatasetTag
  tableIframe: string
  exportUrl: string
  charts: Array<{
    id: number
    label: string
    chartIframe: string
  }>
  meta: Array<{
    id: number
    title: string
    body: string
    expandable: boolean
    expanded: boolean
    order: number
  }>
  dataSource: Array<string>
  forecastVariables: Array<{
    slug: string
    bucketSlug: string
    groupSlug: string
    name: string
    image: string | null
  }>
  forecastVariableLabel: string
}

export type DatasetPageData = {
  dataset: Dataset
  recommendedReports: ReportSummary[]
  recommendedDatasets: DatasetPageData["dataset"][]
  layoutProps?: LayoutProps | LayoutPropsV3
}

export type WebflowPageData = {
  wfHtml: string
  layoutProps?: LayoutProps | LayoutPropsV3
}

export type InitiatePaymentResponse = {
  provider: "paystack" | "stripe"
  reference: string
  amount: number
  displayAmount: number
  currencyCode: string
}

export type InitiatePaymentPayload = {
  planCode: string
  bankTransfer?: boolean
}

export type Topic = {
  name: string
  id: number
  slug: string
  description?: string
  category_name: string | null
  level: "category" | "subcategory"
  parent_id?: number | null
  image?: Image
}

export type NavigationMenuItem = {
  id: number
  label: string
  destination: string
  category_name: string | null
  level: "topnavigation" | "subnavigation"
  priority: "high" | "low" | null
  parent_id?: number | null
  more_topics_visibility_by_screen: Array<"desktop" | "mobile">
  description?: string
}

export type SidebarMenuItem = {
  id: number
  label: string
  destination: string
  category_name: string | null
  icon?: string
  parent_id?: number | null
  description?: string
  children?: Array<SidebarMenuItem>
}

export type SidebarMenuItemV2 = {
  id: number
  name: string
  slug: string
  destination?: string
  isFilterItem: boolean
  isSidebarItem: boolean
  children?: Array<SidebarMenuItemV2>
}

export type Tag = {
  name: string
  id: number
  slug?: string
  description?: string
}

export type Author = {
  id: number
  slug: string
  title?: string
  name?: string
  firstName?: string
  lastName?: string
  image: string
  bio: string | null
  email: string | null
  socialMediaLinks: {
    linkedin: string | null
    twitter: string | null
    facebook: string | null
  }
}

export type Image = {
  altText: string
  sourceUrl: string
  srcSet: string
}

export type SEO = {
  title: string
  description: string
  imageURL?: string
  mainImageText?: string
  faqSchema?: Record<string, any>
  jsonSchema: Record<string, any>
}

export type ReportSummary = {
  id: number
  objectID?: string
  slug: string
  title: string
  summary: string
  date: string
  topics: Topic[]
  tags: Tag[]
  seo: SEO
  image: Image
  imageSrcSet?: string
  author: Author
  type: string
  category?: Topic | null
  mainTopic: Topic | null
}

export type Report = ReportSummary & {
  mainTopic: Topic
  body?: string
}

export type AnonymousUser = {
  userType: "anonymous"
  data: null
  loading?: boolean
}

export type MemberProfile = {
  firstName: string
  lastName: string
  email: string
  emailVerified: boolean
  hasSelectedTopics: boolean
  hasSetPreferences: boolean
  hasCompleteProfile: boolean
  /**
   * As of the time of creating this type. I am not sure what topics type is
   * TODO: Confirm topics type, update topics type definition if needed and remove this docblock
   */
  topics: number[]
  preferences: Array<{
    id: string
    name: string
    description: string
    value: boolean
    type: "push"
    group: "push"
  }>
  jobTitle: string | null
  seniority: string | null
  industry: string | null
  phoneNumber: string | null
  companyName: string | null
  companySize: string | null
  referralSource: string | null
  city: string | null
  country: string | null
  roles: string[]
  corporateSubscriberLicence: LicenceType | null
  corporateSubscriberName: string | null
  corporateSubscriberId: string | null
  profileCompletionDate: string | null
}

export enum LicenceType {
  COMMERCIAL = "commercial",
  STRATEGY = "strategy",
  INVESTOR = "investor",
  PRO = "pro"
}

export type LoggedInUser = {
  data: {
    email: string
    email_verified: string
    /**
     * exp was previously defined with the type: 1659718176. Reason unclear
     * TODO: Investigate why and Remove above note in future
     */
    exp: number
    has_selected_topics: boolean
    has_set_preferences: boolean
    has_complete_profile: boolean
    roles: string[]
    corporate_subscriber_licence: LicenceType | null
    corporate_subscriber_name: string | null
    corporate_subscriber_licence_on_trial: boolean
    corporate_subscriber_id: string | null
    user_id: number
    sub: string
    firstName: string
    nickname: string
    lastName: string
    isNewUser?: boolean
    isAdmin?: boolean
  }
  userType: keyof typeof accounts
  loading?: boolean
}

export type GetAlertProps = {
  tagName: string
  active: boolean
}

export type Member = {
  firstName: string
  lastName: string
  email: string
  username: string
  roles: string[]
  lastLogin: string
  isActive: boolean
}

export type LicenceInformation = {
  category: string
  startDate: string
  endDate: string
  totalSeats: number
  availableSeats: number
  members: Array<Member>
}

export type InviteMemberPayload = {
  email: string
  roles: string[]
}

export type IntelligenceQueryParams = {
  q?: string
  tags?: Array<string>
  limit?: number
  offset?: number
  page?: number
  type?: "datasets" | "reports"
  sort?: "asc" | "desc"
}

export type ScreenerDealsProps = {
  layoutProps?: LayoutPropsV3
}

export type CompanyPageProps = {
  layoutProps?: LayoutPropsV3
}

export type CompanyProfileTrackableSection =
  | "overview_description"
  | "management_team_bio"
