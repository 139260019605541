import {
  BaseElectionEventProperties,
  Race
} from "@/src/elections/business-layer/types"

export type PaginatedResponse<T> = {
  items: T[]
  offset: number
  limit: number
  has_more: boolean
  count: number
}

export type Campaign = {
  race: string
  year: string
  candidate_slug: string
  candidate_name: string
  candidate_party_code: string
  constituency_code: string
  lga_name: string | null
  lga_code: string | null
  state_code: string
  state_name: string
  region_code: string
  running_mate: string | null
  candidate_gender: string | null
  candidate_age: number | null
  candidate_is_incumbent: string | null
  candidate_photo_url: string | null
  party_code: string
  party_color: string
  party_logo_url: string
  votes: number | null
  total_votes: number | null
  won: boolean | null
}

export type Constituency = {
  code: string
  name: string
  race: string
  state_code: string
  state_name: string
}

export type Candidate = {
  slug: string
  full_name: string
  party_code: string
  age: number | null
  gender: string | null
  is_incumbent: string | null
  photo_url: string | null
  party_logo_url: string | null
  state_name: string | null
  bio: null | {
    mandate?: string
    offices?: string
    website?: string
    education?: string[]
    background?: string[]
    mentions?: string[]
    work?: string[]
    read_more?: Array<{ text: string; url: string }>
  }
}

export type State = {
  code: string
  name: string
  region_code: string
}

export type ElectionPreference = {
  election_id: string
  active: boolean
}

export type PartyDominance = {
  race: Race
  party: string
  party_color: string | null
  party_logo_url: string | null
  year: string | number
  /**
   * State code
   */
  state: string
  state_name: string
  lga_name: string
  lga_code: string
  candidate_slug: string | null
  candidate_name: string | null
  candidate_photo_url: string | null
  constituency_name: string
  constituency_code: string
  won: boolean
  vote_count: number
  total_votes: number
  dominance: number | null
  vote_percentage: number | null
}

/**
 * Usually analytic event properties
 */
export type DefaultEventProperties = {
  loggedIn: boolean
  year: string
} & BaseElectionEventProperties

/**
 * @deprecated
 */
export enum PresidentCollationLevel {
  LGA = "lga",
  STATE = "state"
}

export enum ElectionSources2023 {
  TRACKA = 1,
  IREV,
  TV,
  STATE,
  LGA,
  CONSTITUENCY
}
