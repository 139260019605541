import React, { useState } from "react"

import Toast from "../../components/Toast/Toast"

const useToast = () => {
  const [message, setMessage] = useState("")
  const [type, setType] = useState<"success" | "error" | "default">("default")
  const [showToast, setShowToast] = useState(false)

  const showToastMessage = (type: "success" | "error", msg: string) => {
    setMessage(msg)
    setType(type)
    setShowToast(true)
  }

  const closeToast = () => {
    setShowToast(false)
    setMessage("")
    setType("default")
  }

  const toastComponent = showToast && (
    <Toast type={type} message={message} onClose={closeToast} />
  )

  return { showToastMessage, toastComponent }
}

export default useToast
