// configurations
import PageLinks from "@/src/sb/constants/copy/pageLinks"

import { CAREERS_PAGE_LINK, CONTACT_EMAIL, CONTACT_URL } from "../configs"

export const QUICK_LINKS = {
  company: [
    { linkText: "About", href: "/about-us" },
    { linkText: "Careers", href: CAREERS_PAGE_LINK as string },
    {
      linkText: "FAQs",
      href: "https://stears.freshdesk.com/support/solutions"
    },
    { linkText: "Contact", href: CONTACT_URL }
  ],
  legal: [
    { linkText: "Privacy", href: "/privacy-policy/" },
    { linkText: "Terms", href: "/terms-and-conditions/" }
  ],
  products: [
    { linkText: "Insight & Data", href: "/data" },
    { linkText: "Elections", href: PageLinks.elections }
  ]
}
export const STEARS_MISSION = `Our mission is to build the world’s most trusted provider of African data & insight to global organisations.`

export const COPYRIGHT = ` Copyright © Stears ${new Date().getFullYear()} All rights reserved. This material, and
other digital content on this website, may not be reproduced,
published, broadcast, rewritten or redistributed in whole or in
part without written permission from Stears.`

export const BOTTOM_LINKS = [
  { linkText: "Privacy policy", href: "/privacy-policy/" },
  { linkText: "Terms of use", href: "/terms-and-conditions/" },
  { linkText: "Contact", href: CONTACT_EMAIL }
]
