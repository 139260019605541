import { Race } from "./types"

export const ELECTION_PREFERENCES_API_URL = "/membership/election-preferences/"
export const ELECTION_ARTICLE_FEED_API_URL =
  "/content/election-article-summaries/"
export const ELECTIONS_API_BASE_URL = "/elections"
export const ELECTION_CAMPAIGNS_API_URL = `${ELECTIONS_API_BASE_URL}/campaigns/`
export const ELECTION_CAMPAIGN_RESULTS_API_URL = `${ELECTIONS_API_BASE_URL}/campaign-results/`
export const ELECTION_CONSTITUENCIES_API_URL = `${ELECTIONS_API_BASE_URL}/constituencies/`
export const ELECTION_STATES_API_URL = `${ELECTIONS_API_BASE_URL}/states/`
export const ELECTION_CANDIDATES_API_URL = `${ELECTIONS_API_BASE_URL}/candidates/`
export const ELECTION_TIMELINE_API_URL = `${ELECTIONS_API_BASE_URL}/timelines/`
export const ELECTION_PARTY_DOMINANCE_STATE_API_URL = `${ELECTIONS_API_BASE_URL}/party-dominance-by-state/`
export const ELECTION_PARTY_DOMINANCE_LGA_API_URL = `${ELECTIONS_API_BASE_URL}/party-dominance-by-lga/`
export const ELECTION_PARTY_DOMINANCE_CONSTITUENCY_API_URL = `${ELECTIONS_API_BASE_URL}/party-dominance-by-constituency/`
export const ELECTION_LIVE_BLOG_API_URL = `https://cdn.tickaroo.com/api/embed/v4/prefetch/liveblog.json`

export const REFRESH_INTERVAL_SECS = process.env
  .NEXT_PUBLIC_REFRESH_INTERVAL_SECS
  ? parseInt(process.env.NEXT_PUBLIC_REFRESH_INTERVAL_SECS)
  : 120

export const PRESIDENT_REVALIDATE_IN_SECS = process.env
  .NEXT_PUBLIC_PRESIDENT_REVALIDATE_IN_SECS
  ? parseInt(process.env.NEXT_PUBLIC_PRESIDENT_REVALIDATE_IN_SECS)
  : 60

export const REVALIDATE_IN_SECS =
  parseInt(process.env.REVALIDATE_IN_SECS as string) || 1
export const REVALIDATE_LONG_IN_SECS =
  parseInt(process.env.REVALIDATE_LONG_IN_SECS as string) || 60 * 30
export const REVALIDATE =
  (process.env.REVALIDATE as string) === "true" ? REVALIDATE_IN_SECS : false
export const REVALIDATE_LIVE_BLOG_IN_SECS = process.env
  .REVALIDATE_LIVE_BLOG_IN_SECS
  ? parseInt(process.env.REVALIDATE_LIVE_BLOG_IN_SECS)
  : 30

export const UPCOMING_ELECTIONS_YEAR =
  process.env.UPCOMING_ELECTIONS_YEAR || "2023"

export const ELECTION_API_REDESIGN_DATE =
  process.env.NEXT_PUBLIC_ELECTION_API_REDESIGN_DATE || "2023-01-01"

export const GET_NOW = () => {
  const dateTimeString = process.env.NEXT_PUBLIC_REAL_DATETIME
  if (!dateTimeString) return new Date()

  return new Date(dateTimeString)
}

export const RESULT_DISCLAIMER =
  process.env.NEXT_PUBLIC_RESULT_DISCLAIMER ||
  "These results are sourced from INEC's result viewing platform (IREV) and LGA collation centres and should not be taken as final results."

export const UPCOMING_ELECTIONS_VOTING_HOURS = process.env
  .NEXT_PUBLIC_UPCOMING_ELECTIONS_VOTING_HOURS
  ? parseInt(process.env.NEXT_PUBLIC_UPCOMING_ELECTIONS_VOTING_HOURS)
  : 0

export const FETCH_MAX_RECORDS =
  parseInt(process.env.FETCH_MAX_RECORDS as string) ||
  parseInt(process.env.NEXT_PUBLIC_FETCH_MAX_RECORDS as string) ||
  500
export const TABLE_MIN_CAMPAIGNS =
  parseInt(process.env.TABLE_MIN_CAMPAIGNS as string) || 4
export const TABLE_MAX_CAMPAIGNS =
  parseInt(process.env.TABLE_MAX_CAMPAIGNS as string) || "all"

export const HIDDEN_ELECTION_RACES = process.env
  .NEXT_PUBLIC_HIDDEN_ELECTION_RACES
  ? ((process.env.NEXT_PUBLIC_HIDDEN_ELECTION_RACES || "")
      .split(",")
      .map((race) => race.trim()) as Race[])
  : ([] as Race[])

export const PRESIDENT_LGA_MAP =
  process.env.NEXT_PUBLIC_PRESIDENT_LGA_MAP !== "false"
export const TOP_PARTIES_LIVE_RESULTS = (
  process.env.NEXT_PUBLIC_TOP_PARTIES_LIVE_RESULTS || "APC,LP,NNPP,PDP"
).split(",")
