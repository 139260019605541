import * as React from "react"

import { AnalyticsService } from "./AnalyticsService"

type AnalyticsProviderProps = {
  children: React.ReactNode
}

export const AnalyticsContext = React.createContext<AnalyticsService | null>(
  null
)

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const analytics = React.useMemo(() => AnalyticsService.create(), [])
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
