// API URLs
export const SB_PLANS_API_URL = "/membership/plans/"
export const SB_INITIATE_PAYMENT_API_URL = "/membership/payments/"
export const SB_VERIFY_PAYMENT_API_URL = "/membership/payment-verify/"
export const SB_MEMBER_PROFILE_API_URL = "/membership/profile/"
export const SB_MEMBER_GET_ALERT_API_URL =
  "/membership/update-mailing-list-tags/"
export const SB_TAGS_CLASSIFICATION_API_URL = "/content/tags/"
export const SB_CITIES_API_URL = "/membership/profile/cities"
export const SB_SEARCH_API_URL = "/content/search/"
export const SB_FILTER_API_URL = "/content/filters/"
export const SB_DATASET_API_URL = "/content/datasets/"
export const SB_ARTICLE_API_URL = "/content/articles/"
export const SB_ARTICLE_AUTHOR_API_URL = "/content/authors/"
export const SB_ARTICLE_SUMMARIES_API_URL = "/content/article-summaries/"
export const SB_LICENCE_INFORMATION_API_URL = "/membership/licence-information/"
export const SB_INVITE_TEAM_MEMBER_API_URL = "/membership/invite-team-member/"
export const SB_TEAM_LICENSE_MEMBER_API_URL = "/membership/licence/members"
export const SB_RESEND_LICENSE_MEMBER_INVITE_API_URL =
  "/membership/licence/resend-invitation/"

export const PAYSTACK_PUBLIC_KEY =
  process.env.NEXT_PUBLIC_PAYSTACK_PUBLIC_KEY ?? ""
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ?? ""
export const TYPEFORM_API_BASE_URL =
  process.env.NEXT_PUBLIC_TYPEFORM_API_BASE_URL ?? ""
export const TYPEFORM_KEY = process.env.TYPEFORM_KEY ?? ""
export const TYPEFORM_WORKSPACE_ID =
  process.env.NEXT_PUBLIC_TYPEFORM_WORKSPACE_ID ?? ""
export const READING_TIME_BENCHMARK_SEC = process.env
  .NEXT_PUBLIC_READING_TIME_BENCHMARK_SEC
  ? Number(process.env.NEXT_PUBLIC_READING_TIME_BENCHMARK_SEC)
  : 300

export const DEKSTOP_SINGLE_COLUMN_TOPIC_CATEGORIES: Array<string> = (
  process.env.NEXT_PUBLIC_DEKSTOP_SINGLE_COLUMN_TOPIC_CATEGORIES || ""
)
  .toLowerCase()
  .split(",")

export const DESKTOP_PREFERRED_MORE_MENU_COPY: string =
  process.env.NEXT_PUBLIC_DESKTOP_PREFERRED_MORE_MENU_COPY || "More Insights"

export const MOBILE_PREFERRED_MORE_MENU_COPY: string =
  process.env.NEXT_PUBLIC_MOBILE_PREFERRED_MORE_MENU_COPY || "Insights"
