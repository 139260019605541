import { BASE_URL } from "@/src/common/config"
import { pages } from "@/src/sb/constants/copy"

export const appendSlash = (r: string) =>
  !r ? "" : r.endsWith("/") ? r : r + "/"

export class URLFactory {
  static commonPages = pages // TODO: refactor to use same factory
  static getUrl(el: keyof typeof URLFactory, ...args: any[]) {
    return typeof URLFactory[el] === "function"
      ? (URLFactory[el] as any)(...args)
      : URLFactory[el]
  }
  static absolute(el: keyof typeof URLFactory, ...args: any[]) {
    return BASE_URL + URLFactory.getUrl(el, ...args)
  }

  static report(slug: string, isFree = false) {
    return appendSlash(`${!isFree ? "/premium" : ""}/article/${slug}`)
  }

  static topicPage(slug: string) {
    return appendSlash(`/topics/${slug}`)
  }

  static dataPageRoot() {
    return appendSlash(`/data`)
  }

  static dataPage(slug: string) {
    return appendSlash(`${URLFactory.dataPageRoot()}${slug}`)
  }

  static country(slug: string) {
    return appendSlash(`${URLFactory.dataPageRoot()}countries/${slug}`)
  }

  static writer(slug: string) {
    return appendSlash(`/writer/${slug}`)
  }
}
