import ErrorIcon from "@/public/assets/icons/error-icon.svg"
import SuccessIcon from "@/public/assets/icons/success-icon.svg"
import Image from "next/image"
import React, { useEffect, useState } from "react"

type ToastProps = {
  type: "success" | "error" | "default"
  message: string
  onClose: () => void
}

const Toast: React.FC<ToastProps> = ({ type, message, onClose }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!visible) {
      const timer = setTimeout(() => {
        onClose()
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [visible, onClose])

  const handleClose = () => {
    setVisible(false)
  }

  const getIcon = () => {
    switch (type) {
      case "success":
        return (
          <Image
            src={SuccessIcon}
            width={16}
            height={16}
            alt="toast-success-icon"
          />
        )
      case "error":
        return (
          <Image
            src={ErrorIcon}
            width={16}
            height={16}
            alt="toast-error-icon"
          />
        )
    }
  }

  return (
    <div
      className={`fixed inset-x-0 top-0 flex  justify-center p-4 transition-all duration-500 ${
        visible ? "opacity-100  translate-y-0" : "opacity-0 -translate-y-full"
      }`}
    >
      <div className="flex items-center justify-between w-full max-w-[400px] p-3 bg-[#1A1D21] border border-[#4B5563] shadow text-gray-500">
        <div className="flex items-center justify-center w-5 h-5 rounded-full">
          {getIcon()}
          <span className="sr-only">{type} icon</span>
        </div>
        <div
          className={`ml-3 text-xs font-bold  ${
            type === "error" ? "text-[#F05042]" : "text-white"
          }`}
        >
          {message}
        </div>
        <button
          type="button"
          className="ml-auto p-1.5 text-gray-400 mb-1.5"
          onClick={handleClose}
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Toast
