import axiosInstance from "@/src/common/axiosInstance"
import axios, { Axios, AxiosRequestConfig, AxiosResponse } from "axios"

export class FetcherError extends Error {
  public info?: string
  public data?: object
  public status?: number
}

export class ExternalHTTP {
  constructor(protected readonly axios: Axios = axiosInstance) {}

  static is404(e: FetcherError | any) {
    if (e instanceof FetcherError) {
      return e.status === 404
    }
    return false
  }

  async get<T>(
    url: string,
    token?: string,
    extraConfig?: AxiosRequestConfig
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json" }
    }
    if (token) {
      config.headers!.Authorization = "Bearer " + token
    }
    try {
      const { data } = await (this.axios.get(encodeURI(url), {
        ...config,
        ...extraConfig,
        headers: { ...config.headers, ...extraConfig?.headers }
      }) as Promise<AxiosResponse<T>>)
      return data
    } catch (e: any) {
      const err = new FetcherError(
        "Could not get URL " + url + ". Please refresh and try again."
      )
      err.info = e.message
      if (axios.isAxiosError?.(e)) {
        err.status = e.response?.status
        err.info = e.message
        err.data = e.response?.data as any
      } else {
        err.data = JSON.stringify(e) as any
      }
      throw err
    }
  }

  async post<T, D extends object = any>(
    url: string,
    payload: D,
    token?: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      data: payload,
      headers: { "Content-Type": "application/json" }
    }
    if (token) {
      config.headers!.Authorization = "Bearer " + token
    }

    try {
      const { data } = await (this.axios.post(
        encodeURI(url),
        payload,
        config
      ) as Promise<AxiosResponse<T, D>>)
      return data
    } catch (e: any) {
      const err = new FetcherError(
        "Could not get URL " + url + ". Please refresh and try again."
      )
      if (axios.isAxiosError?.(e)) {
        err.status = e.response?.status
        err.info = e.message
        err.data = e.response?.data as any
      } else {
        err.data = JSON.stringify(e) as any
      }
      throw err
    }
  }

  /**
   * implementation of patch is a replica of post with a diff of method name
   *
   * TODO: Refacotor ExternalHTTP
   *
   * @param url
   * @param payload
   * @param token
   */
  async patch<T, D extends object = any>(
    url: string,
    payload: D,
    token?: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      data: payload,
      headers: { "Content-Type": "application/json" }
    }
    if (token) {
      config.headers!.Authorization = "Bearer " + token
    }
    const { data } = await (this.axios.patch(
      encodeURI(url),
      payload,
      config
    ) as Promise<AxiosResponse<T, D>>)
    return data
  }

  async delete<T>(
    url: string,
    token?: string,
    extraConfig?: AxiosRequestConfig
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json" }
    }
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    if (extraConfig) {
      Object.assign(config, extraConfig)
    }
    try {
      const { data } = await this.axios.delete<T>(encodeURI(url), config)
      return data
    } catch (e: any) {
      const err = new FetcherError(
        `Could not delete URL ${url}. Please refresh and try again.`
      )

      if (axios.isAxiosError(e)) {
        err.status = e.response?.status
        err.data = e.response?.data as any
      } else {
        err.data = JSON.stringify(e) as any
      }
      throw err
    }
  }
}

const ExternalHTTPInstance = new ExternalHTTP()

export default ExternalHTTPInstance
