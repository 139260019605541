import { GROWTH_BOOK_KEY, IS_PRODUCTION_ENVIRONMENT } from "@/src/common/config"
import { GrowthBook as GB } from "@growthbook/growthbook-react"

export const growthBookCommonConfig = {
  apiHost: "https://cdn.growthbook.io",
  clientKey: GROWTH_BOOK_KEY
}

const GrowthBookInstance = new GB({
  ...growthBookCommonConfig,
  enableDevMode: !IS_PRODUCTION_ENVIRONMENT,
  subscribeToChanges: false,
  trackingCallback: () => {
    /*Noop*/
  }
})

export default GrowthBookInstance
