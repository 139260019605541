import { ExternalHTTP, ExternalHTTPInstance } from "@/src/common/data-layer"

import ElectionsAPIInstance, { ElectionsAPI } from "./datasources/ElectionsAPI"
import upcomingElections from "./mocks/upcoming-elections.json"

const Data = {
  upcomingElections
}

export {
  Data,
  ExternalHTTP,
  ExternalHTTPInstance,
  ElectionsAPI,
  ElectionsAPIInstance
}
