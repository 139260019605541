import { BASE_URL, IS_PRODUCTION_ENVIRONMENT } from "@/src/common/config"
import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"

import { SEOProps, getSEOSetting } from "./settings"

export const SEO = (props: SEOProps) => {
  const router = useRouter()

  const settings = getSEOSetting(router.asPath, props)
  const pathname = router.asPath.split("?")[0]
  const url = pathname === "/" ? BASE_URL : `${BASE_URL}${pathname}`

  return (
    <Head>
      <title>{settings.title}</title>
      <meta name="description" content={settings.description} />
      <link rel="canonical" href={url} key="canonical" />
      {IS_PRODUCTION_ENVIRONMENT ? (
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {settings.tags.map((tag, index) => {
        if ("name" in tag) {
          return (
            <meta
              key={tag.key || index}
              name={tag.name}
              content={tag.content}
            />
          )
        }
        return (
          <meta
            key={tag.key || index}
            property={tag.property}
            content={tag.content}
          />
        )
      })}
      <link
        rel="icon"
        type="image/png"
        href={props.favicon || "/favicon.png"}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(settings.linkedData)
        }}
      />
    </Head>
  )
}

export default SEO
