import { MENU_ITEM_TO_IGNORE, TOPICS_TO_IGNORE } from "@/src/common/config"
import { ExternalHTTPInstance } from "@/src/common/data-layer"
import { NavigationMenuItem } from "@/src/sb/business-layer/types"
import { Topic } from "@/src/sb/core"
import {
  FETCH_TOPICS_ENDPOINT,
  NAVIGATION_MENU_ENDPOINT
} from "@/src/sb/core/api/endpoints"

export class CommonAPI {
  constructor(private readonly externalHTTPInstance = ExternalHTTPInstance) {}

  async fetchAllTopics(
    url: string = FETCH_TOPICS_ENDPOINT,
    config: Record<string, any> = {}
  ) {
    return (
      await this.externalHTTPInstance.get<Topic[]>(url, undefined, config)
    ).filter((topic) => !TOPICS_TO_IGNORE.includes(topic.slug))
  }

  async fetchNavigationMenu(
    url: string = NAVIGATION_MENU_ENDPOINT,
    config: Record<string, any> = {}
  ): Promise<NavigationMenuItem[]> {
    return (
      await this.externalHTTPInstance.get<NavigationMenuItem[]>(
        url,
        undefined,
        config
      )
    ).filter((MenuItem) => !MENU_ITEM_TO_IGNORE.includes(MenuItem.label))
  }

  public async visitorIdentification(
    url: string,
    payload: object
  ): Promise<{ data: { token: string } }> {
    return this.externalHTTPInstance.post(url, payload)
  }
}

const CommonAPIInstance = new CommonAPI(ExternalHTTPInstance)
export default CommonAPIInstance
