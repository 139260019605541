const defaultTheme = require("tailwindcss/defaultTheme")

/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: ["class"],
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx}",
    "./src/components/**/*.{js,ts,jsx,tsx}",
    "./src/**/components/**/*.{js,ts,jsx,tsx}",
    "./src/stories/**/*.{js,ts,jsx,tsx}"
  ],
  theme: {
    extend: {
      keyframes: {
        wiggle: {
          "0%, 100%": {
            transform: "translateX(0%)",
            "transform-origin": "50% 50%"
          },
          "15%": { transform: "translateX(-10px) rotate(6deg)" },
          "30%": { transform: "translateX(5px) rotate(-6deg)" },
          "45%": { transform: "translateX(-5px) rotate(3.6deg)" },
          "60%": { transform: "translateX(3px) rotate(-2.4deg)" },
          "75%": { transform: "translateX(-2px) rotate(1.2deg)" }
        }
      },

      animation: {
        wiggle: "wiggle 1s 3 both"
      },

      backgroundImage: {
        welcome: "url('~/public/assets/img/welcome-banner-mobile.png')",
        "welcome-desktop":
          "url('~/public/assets/img/welcome-banner-desktop.png')"
      },
      screens: {
        // see https://www.geeksforgeeks.org/how-to-target-desktop-tablet-and-mobile-using-media-query/#:~:text=If%20the%20media%20query%20is,and%20ipads%20max%2Dwidth%3A%20767px
        portrait_tablet: { max: "1024px" },
        tablet: { max: "768px" },
        mobile: { max: "640px" },
        // below added for merge with elections repo
        xs: { min: "369px" },
        "xs!": { min: "369px", max: "767px" },
        "-xs": { max: "368px" }, // opposite of "xs"
        "-sm": { max: "640px" }, // opposite of "sm" (same as "mobile")
        "-md": { max: "768px" }, // opposite of "md" (same as "tablet")
        "-lg": { max: "1024px" } // opposite of "lg" (same as "portrait_tablet")
      },
      boxShadow: {
        min: "0px 4px 10px rgba(0, 0, 0, 0.05)",
        // below added for merge with elections repo
        xxs: "0px 1px 2px rgba(0, 0, 0, 0.1)",
        xs: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        sm: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
        "-sm": "0px -10px 30px 0px rgba(0, 0, 0, 0.08)"
      },
      spacing: {
        0.75: "0.1875rem", //3px
        18: "4.5rem" //72px
      },
      fontSize: {
        //Gotten from  https://www.figma.com/file/xkbGgPNUMl15DGSTOOgHcq/Vector?node-id=83523%3A8545
        // paragraphs
        p1: "1.125rem", //18px
        p3: "0.875rem", // 14px
        p4: "0.75rem", // 12px
        p5: "0.625rem", // 10px
        // [font-size, line-height]
        p_18: ["1.125rem", "1.875rem"], // "18px", "30px"
        p_16: ["1rem", "1.5rem"], //"16px", "24px"
        p_15: ["0.9375rem", "1.5625rem"], //"15px", "25px"
        p_14: ["0.875rem", "1.25rem"], // "14px", "20px"
        p_12: ["0.75rem", "1.125rem"], // "12px", "18px"
        p_12b: ["0.75rem", "1.5rem"], // "12px", "24px"
        p_10: ["0.625rem", "1rem"], // "10px", "16px"
        // button fonts
        btn_18: ["1.125rem", "1.125rem"], // "18px","18px"
        btn_16: ["1rem", "1rem"], //"16px", "16px"
        btn_15: ["0.9375rem", "0.9375rem"], //"15px", "15px"
        btn_14: ["0.875rem", "0.875rem"], // "14px", "14px"
        btn_12: ["0.75rem", "0.75rem"], // "12px", "12px"
        btn_10: ["0.625rem", "0.625rem"], // "10px", "10px"
        // Headings
        h1: ["3rem", "4rem"], // "48px", "64px"
        h2: ["2rem", "2.75rem"], // "32px", "44px"
        h3: ["1.75rem", "2.5rem"], // "28px", "40px"
        h4: ["1.5rem", "2.25rem"], // "24px", "36px"
        h5: ["1.25rem", "1.875rem"], // "20px", "30px"
        h6: ["1.125rem", "1.5rem"], // "18px", "20px"
        article_card_title: ["1.25rem", "1.625rem"], // "20px" , "26px"
        article_card_subtitle: ["1rem", "1.375rem"], // "16px" , "22px"
        lineHeight: {
          11: "2.75rem"
        },
        // below added for merge with elections repo
        p2: "1rem", // 16px
        p_32: ["2rem", "3.125rem"], // "32px", "50px"
        p_28: ["1.75rem", "2.5rem"], // "28px", "40px"
        p_20: ["1.25rem", "2rem"], // "20px", "32px"
        p_18b: ["1.125rem", "1.5rem"], // "18px", "24px"
        p_18_18: ["1.125rem", "1.125rem"], // "18px", "24px"
        p_15_15: ["0.9375rem", "0.9375rem"], //"15px", "25px"
        p_12_12: ["0.75rem", "0.75rem"] // "12px", "12px"
      },
      fontWeight: {
        semibold: 500,
        bold: 700,
        regular: 400
      },
      borderWidth: {
        w_0_5: "0.5px"
      },
      colors: {
        // copied from: https://www.figma.com/file/xkbGgPNUMl15DGSTOOgHcq/Vector?node-id=81451%3A85283
        stears_red: "#F05042",
        stears_danger: "#DC2626",
        stears_negative: "#E11900",
        stears_warning: "#FFC043",
        stears_positive: "#05944F",
        stears_success: "#3AA76D",
        stears_accent: "#276EF1",
        stears_white: "#FFF",
        stears_black: "#000",
        apc: {
          100: "#DBEAFE",
          600: "#2563EB"
        },
        pdp: {
          100: "#D1FAE5",
          600: "#46AE4E"
        },
        border: {
          10: "#E5E7EB",
          20: "#C6C9CD",
          50: "#4B5563",
          100: "#4B5563"
        },
        red: {
          50: "#FEF2F2",
          100: "#FEE2E2",
          200: "#FECACA",
          400: "#F87171",
          600: "#DC2626",
          800: "#991B1B"
        },
        yellow: {
          50: "#FFFBEB",
          100: "#FFFBEB",
          200: "#FDE68A",
          400: "#FBBF24",
          600: "#D97706",
          800: "#92400E"
        },
        green: {
          legend: "#90EE90",
          50: "#ECFDF5",
          100: "#D1FAE5",
          200: "#A7F3D0",
          400: "#34D399",
          600: "#46AE4E",
          800: "#047857"
        },
        blue: {
          50: "#F6FAFF",
          100: "#DBEAFE",
          200: "#BFDBFE",
          400: "#60A5FA",
          600: "#2563EB",
          800: "#1E40AF"
        },
        accent: {
          50: "#F6FAFF",
          100: "#DBEAFE",
          200: "#BFDBFE",
          400: "#60A5FA",
          600: "#2563EB",
          800: "#1E40AF"
        },
        neutral: {
          200: "#EEF1F6"
        },
        gray: {
          legend: "#AAAAAA",
          5: "#ABACAE",
          10: "#DBDCDD",
          20: "#C6C9CD",
          50: "#F9FAFB",
          100: "#F3F4F6",
          200: "#E5E7EB",
          400: "#9CA3AF",
          600: "#4B5563",
          800: "#1F2937"
        }
      },
      fontFamily: {
        jakarta: "Plus Jakarta Display",
        halant: "Halant",
        gambetta: "gambetta",
        sans: ["Plus Jakarta Sans", ...defaultTheme.fontFamily.sans],
        mulish: ["var(--font-mulish)", ...defaultTheme.fontFamily.sans] // installed using next/font/google package
      },
      height: {
        13.5: "3.375rem", // 54px
        46: "11.5rem", // 184px
        50: "12.5rem", // 200px
        68: "17rem", // 272px
        90: "22.5rem", // 360px
        110: "27.5rem" // 440px
      },
      width: {
        30: "7.5rem", // 120px
        41: "10.25rem", // 164px
        42: "10.5rem", // 168px
        43: "10.75rem", // 172px
        55: "13.75rem", // 220px
        140: "35rem", // 540px
        150: "37.5rem", // 600px
        160: "40rem" // 640px
      }
    }
  },
  safelist: [
    // ensures that tailwind always loads custom theme
    { pattern: /(text|bg)-(red|blue|green|yellow)-\d+/ },
    { pattern: /(text|bg)-stears_[a-z]+/ }
  ],
  plugins: []
}
