/**
 * Setting this to any falsy value disables Sentry from capturing bugs.
 */
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const ENABLE_SENTRY = "capture_sentry_logs"
export const SENTRY_ENVIRONMENTS_FLAG = "allowed_sentry_enviroments"

export const SentryOptions: Record<
  string,
  boolean | string | number | undefined
> = {
  dsn: SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  /**
   * The intent for setting this low to 0.1(10%) is to minimize the possibility
   * of using up our transaction quota for the month, we can always bump it up
   * if sampled data is not enough
   */
  tracesSampleRate: 0.1
}
