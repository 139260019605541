import { ExternalHTTPInstance } from "@/src/common/data-layer"
import {
  LiveBlogResponse,
  PartyDominanceApiParams,
  RawElectionTime
} from "@/src/common/types"
import { qs } from "@/src/common/utils"
import {
  ELECTION_TICKAROO_CLIENT_ID,
  ELECTION_TICKAROO_POST_LIMIT,
  ELECTION_TICKAROO_THEME_ID
} from "@/src/elections/business-layer/constants"

import {
  ELECTION_ARTICLE_FEED_API_URL,
  ELECTION_CAMPAIGNS_API_URL,
  ELECTION_CAMPAIGN_RESULTS_API_URL,
  ELECTION_CANDIDATES_API_URL,
  ELECTION_CONSTITUENCIES_API_URL,
  ELECTION_LIVE_BLOG_API_URL,
  ELECTION_PARTY_DOMINANCE_CONSTITUENCY_API_URL,
  ELECTION_PARTY_DOMINANCE_LGA_API_URL,
  ELECTION_PARTY_DOMINANCE_STATE_API_URL,
  ELECTION_PREFERENCES_API_URL,
  ELECTION_STATES_API_URL,
  ELECTION_TIMELINE_API_URL,
  FETCH_MAX_RECORDS
} from "../../business-layer/config"
import { Article, RegionSummary } from "../../business-layer/types"
import {
  Campaign,
  Candidate,
  Constituency,
  ElectionPreference,
  PaginatedResponse,
  PartyDominance
} from "../types"

export class ElectionsAPI {
  constructor(private readonly externalHTTPInstance = ExternalHTTPInstance) {}
  async updatePreferences(
    preferences: Array<ElectionPreference>,
    token: string
  ): Promise<Array<ElectionPreference>> {
    return this.externalHTTPInstance.post<Array<ElectionPreference>>(
      ELECTION_PREFERENCES_API_URL,
      preferences,
      token
    )
  }

  async fetchAllCampaignResults(
    query: Record<string, string | undefined> = {},
    chunkSize = FETCH_MAX_RECORDS
  ): Promise<PaginatedResponse<Campaign>> {
    return this.fetchAll<Campaign>(
      this.fetchCampaignResults.bind(this),
      query,
      chunkSize
    )
  }

  async fetchAllCampaignResultsSWRFetcher(
    url: string,
    query: Record<string, string | undefined> = {},
    chunkSize = FETCH_MAX_RECORDS
  ): Promise<PaginatedResponse<Campaign>> {
    return this.fetchAll<Campaign>(
      (query) => {
        return this.externalHTTPInstance.get<PaginatedResponse<Campaign>>(
          url + qs(query)
        )
      },
      query,
      chunkSize
    )
  }

  async fetchAllConstituencies(
    query: Record<string, string | undefined> = {},
    chunkSize = FETCH_MAX_RECORDS
  ): Promise<PaginatedResponse<Constituency>> {
    return this.fetchAll<Constituency>(
      this.fetchConstituencies.bind(this),
      query,
      chunkSize
    )
  }

  async fetchCampaignResults(
    query: Record<string, string | undefined>
  ): Promise<PaginatedResponse<Campaign>> {
    return this.externalHTTPInstance.get<PaginatedResponse<Campaign>>(
      ELECTION_CAMPAIGN_RESULTS_API_URL + qs(query)
    )
  }

  async fetchCampaigns(
    query: Record<string, string | undefined>
  ): Promise<PaginatedResponse<Campaign>> {
    return this.externalHTTPInstance.get<PaginatedResponse<Campaign>>(
      ELECTION_CAMPAIGNS_API_URL + qs(query)
    )
  }

  async fetchStates(): Promise<PaginatedResponse<RegionSummary>> {
    const states = await this.externalHTTPInstance.get<
      PaginatedResponse<RegionSummary>
    >(ELECTION_STATES_API_URL)
    return (
      states?.items
        ? states
        : {
            items: states || [],
            limit: 0,
            offset: 0,
            count: (states as any).length,
            has_more: false
          }
    ) as PaginatedResponse<RegionSummary>
  }

  async fetchConstituencies(
    query: Record<string, string | undefined> = {}
  ): Promise<PaginatedResponse<Constituency>> {
    return this.externalHTTPInstance.get<PaginatedResponse<Constituency>>(
      ELECTION_CONSTITUENCIES_API_URL + qs(query)
    )
  }

  async fetchCandidate(slug: string): Promise<Candidate> {
    return this.externalHTTPInstance.get<Candidate>(
      ELECTION_CANDIDATES_API_URL + slug
    )
  }

  async fetchElectionArticles(
    query: Record<string, string | undefined> = {},
    token?: string
  ): Promise<PaginatedResponse<Article>> {
    return this.externalHTTPInstance.get<PaginatedResponse<Article>>(
      ELECTION_ARTICLE_FEED_API_URL +
        qs({
          ...query,
          personalized: "false",
          sort: "desc",
          limit: "3"
        }),
      token
    )
  }

  private async fetchAll<T>(
    fn: (
      query: Record<string, string | undefined>
    ) => Promise<PaginatedResponse<any>>,
    query: Record<string, string | undefined>,
    chunkSize = FETCH_MAX_RECORDS
  ): Promise<PaginatedResponse<T>> {
    let hasMore = true
    const limit = chunkSize.toString()
    let allRecords: T[] = []
    while (hasMore) {
      const records = await fn({
        ...query,
        offset: allRecords.length.toString(),
        limit
      })
      allRecords = allRecords.concat((records?.items as T[]) || [])
      hasMore = records?.has_more
    }
    return {
      items: allRecords,
      offset: 0,
      limit: allRecords.length,
      count: allRecords.length,
      has_more: false
    }
  }

  public async fetchElectionsDataTimeline(): Promise<RawElectionTime[]> {
    return this.externalHTTPInstance.get<RawElectionTime[]>(
      ELECTION_TIMELINE_API_URL
    )
  }

  public async fetchLiveBlog(liveblogId: string): Promise<LiveBlogResponse> {
    return this.externalHTTPInstance.get<LiveBlogResponse>(
      ELECTION_LIVE_BLOG_API_URL,
      undefined,
      {
        params: {
          client_id: ELECTION_TICKAROO_CLIENT_ID,
          liveblogId,
          themeId: ELECTION_TICKAROO_THEME_ID,
          limit: ELECTION_TICKAROO_POST_LIMIT,
          webEmbedDefaultConstraint: "full"
        }
      }
    )
  }

  public async fetchPartyDominanceByState(
    params: PartyDominanceApiParams
  ): Promise<PartyDominance[]> {
    return this.externalHTTPInstance.get<PartyDominance[]>(
      ELECTION_PARTY_DOMINANCE_STATE_API_URL,
      undefined,
      { params }
    )
  }

  public async fetchPartyDominanceByConstituency(
    params: PartyDominanceApiParams
  ): Promise<PartyDominance[]> {
    return this.externalHTTPInstance.get<PartyDominance[]>(
      ELECTION_PARTY_DOMINANCE_CONSTITUENCY_API_URL,
      undefined,
      { params }
    )
  }

  public async fetchPartyDominanceByLGA(
    params: PartyDominanceApiParams
  ): Promise<PartyDominance[]> {
    return this.externalHTTPInstance.get<PartyDominance[]>(
      ELECTION_PARTY_DOMINANCE_LGA_API_URL,
      undefined,
      { params }
    )
  }
}

export default new ElectionsAPI(ExternalHTTPInstance)
