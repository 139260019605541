// all envs are initialized here
import { SB_API_URL } from "../../common/config"

export const CAREERS_PAGE_LINK = process.env.NEXT_PUBLIC_CAREERS_PAGE_LINK || ""
export const OLD_SITE = process.env.OLD_SITE_URL

export const FRESHDESK_WIDGET_ID = process.env.FRESHDESK_WIDGET_ID
export const TEAM_SUBSCRIPTION_LINK =
  process.env.NEXT_PUBLIC_TEAM_SUBSCRIPTION ||
  "https://share.hsforms.com/13ObtRbV5RMS-Kut-3vvj4g4uj82"

export const STEARS_DATA_HOME =
  process.env.NEXT_PUBLIC_STEARS_DATA_HOME || "https://stearsdata.com/"

export const CONTACT_EMAIL = process.env.NEXT_PUBLIC_CONTACT_EMAIL || ""
export const CONTACT_URL =
  process.env.NEXT_PUBLIC_CONTACT_URL ||
  "https://stears.freshdesk.com/support/tickets/new"

// API
export const PAGINATION_LIMIT = +(process.env.NEXT_PUBLIC_PAGINATION_LIMIT || 8)

// Articles
export const FREEMIUM_TAG_ID = process.env.NEXT_PUBLIC_FREEMIUM_TAG_ID
export const PREMIUM_TAG_ID = process.env.NEXT_PUBLIC_PREMIUM_TAG_ID

// Google Optimize
export const OPT_CONTAINER_ID = process.env.OPT_CONTAINER_ID

// Analytics
export const NEXT_PUBLIC_MIXPANEL_TOKEN =
  (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string) || ""

// SEO
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? null
export const CLARITY_PROJECT_ID =
  process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID ?? null
export const HOTJAR_ID = +(process.env.NEXT_PUBLIC_HOTJAR_ID as string)
export const LANDING_PAGE_SEO_IMAGE = `/landing-page/seo.png`

export const GOOGLE_SITE_VERIFICATION_1 = process.env
  .NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_1 as string
export const GOOGLE_SITE_VERIFICATION_2 = process.env
  .NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_2 as string
export const POCKET_SITE_VERIFICATION = process.env
  .NEXT_PUBLIC_POCKET_SITE_VERIFICATION as string
export const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID as string

export const RECAPTCHA_PUBLIC_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY ||
  "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"

// Next Auth
export const API_TOKEN_ENDPOINT = `${SB_API_URL}/api/v2/auth/token/`

// client endpoints (NextJS hosted). No prefix
export const CLIENT_AUTH_LOGIN_ENDPOINT = "/api/auth/callback/credentials/"
export const CLIENT_AUTH_LOGOUT_ENDPOINT = "/api/auth/signout/"

export const SB_LEGACY_LOGIN_URL = `${SB_API_URL}/api/v2/auth/legacy-login/`
export const SB_LEGACY_SIGNOUT_URL = "/accounts/signout/"

export const API_CSRF_TOKEN_ENDPOINT = `${SB_API_URL}/api/v2/csrf/`
export const API_REFRESH_TOKEN_ENDPOINT = `${SB_API_URL}/api/v2/auth/token/refresh/`
export const REFRESH_TOKENS_AFTER_MS = 23 * 60 * 60 * 1000

export const GOOGLE_AUTH_CLIENT_ID = process.env.GOOGLE_AUTH_CLIENT_ID as string
export const GOOGLE_AUTH_SECRET = process.env.GOOGLE_AUTH_SECRET as string

export const APPLE_KEY_ID = process.env.APPLE_KEY_ID as string

export const APPLE_PRIVATE_KEY = process.env.APPLE_PRIVATE_KEY as string
export const APPLE_ID = process.env.APPLE_ID as string
export const APPLE_TEAM_ID = process.env.APPLE_TEAM_ID as string

export const SUPPORT_INBOX_EMAIL_ADDRESS =
  process.env.SUPPORT_INBOX_EMAIL_ADDRESS

export const HELP_LINK =
  process.env.NEXT_PUBLIC_HELP_LINK ||
  "https://stears.freshdesk.com/support/tickets/new"
export const V2_FEATURE_TOGGLES = new Set(
  (process.env.NEXT_PUBLIC_V2_FEATURE_TOGGLES || "")
    .toLowerCase()
    .split(",")
    .map((feature) => feature.trim())
)

export const SHOW_MOBILE_DOWNLOAD_PROMPT = true

export const MOBILE_APP_DOWNLOAD_LINK =
  process.env.NEXT_PUBLIC_MOBILE_APP_DOWNLOAD_LINK ||
  "https://onelink.to/uu49gu"

export const MOBILE_IOS_DOWNLOAD_LINK =
  process.env.NEXT_PUBLIC_IOS_DOWNLOAD_LINK || MOBILE_APP_DOWNLOAD_LINK

export const MOBILE_ANDROID_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.stearsng.sb_mobile"

export const MOBILE_APP_SCHEME_URL =
  process.env.NEXT_PUBLIC_MOBILE_APP_SCHEME_URL || "stears://www.stears.co"
export const MOBILE_APP_SCHEME_MY_ELECTIONS_PREFERENCE = `${MOBILE_APP_SCHEME_URL}/elections/election-race`
export const MOBILE_APP_SCHEME_HOME = `${MOBILE_APP_SCHEME_URL}/home`

export const DEFAULT_ANALYTICS_COLLECTORS = (
  process.env.NEXT_PUBLIC_DEFAULT_ANALYTICS_COLLECTORS || "mixpanel,ga"
).split(",")

export const ENERGY_TOPIC_SLUG: string =
  process.env.NEXT_PUBLIC_ENERGY_SLUG || ""

export const ENERGY_PRICE_TRACKER_SLUG: string =
  process.env.NEXT_PUBLIC_ENERGY_PRICE_TRACKER_SLUG || ""

export const UNCOMPLETED_PROFILE_DEMO_FORM_URL =
  process.env.NEXT_PUBLIC_UNCOMPLETED_PROFILE_DEMO_FORM_URL ?? "/"
export const COMPLETED_PROFILE_DEMO_FORM_URL =
  process.env.NEXT_PUBLIC_COMPLETED_PROFILE_DEMO_FORM_URL ?? "/"
export const DATA_PAGE_BASE_PATH = process.env.DATA_PAGE_BASE_PATH ?? "/data"
export const WEBFLOW_SECTIONS_BASE_PATH =
  process.env.WEBFLOW_SECTIONS_BASE_PATH ?? "/404"
export const OPEN_DATA_PAGE_BASE_PATH =
  process.env.OPEN_DATA_PAGE_BASE_PATH || "/open-data"
export const FX_RATES_CSV_URL =
  "https://stears-data-production.s3.amazonaws.com/fmdq_summary.csv"
export const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ?? ""
