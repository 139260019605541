import {
  ANALYTICS_SUBSCRIBE,
  ANALYTICS_SUBSCRIBE_BUTTON
} from "@/src/common/business-layer/constants"
import {
  AnalyticsProfileUpdateProps,
  OperatingSystems
} from "@/src/common/types"
import { removeUndefined } from "@/src/common/utils"
import { ELECTIONS_BANNER } from "@/src/elections/business-layer/constants"
import {
  BaseElectionEventProperties,
  CandidateCampaignInfo,
  ClickArticleInFeedEventProperties,
  ClickElectionCandidate,
  ClickElectionDominantParty,
  ClickElectionMapEventProperties,
  ClickMyElectionsEventProperties,
  ClickNavigationTimelineYear,
  ClickStateDropdownItemEventProperties,
  ClickViewAllElectionCandidates,
  ClickViewAllElectionParties,
  FollowElectionEventProperties,
  SaveElectionPreferencesEventProperties,
  SocialShareEventProps,
  ViewElectionCandidateEventProperties,
  ViewElectionEventProperties
} from "@/src/elections/business-layer/types"
import { CompanyProfileTrackableSection } from "@/src/sb/business-layer/types"

import { SessionUser } from "../../core/models/auth"
import { AnalyticsAdapter, EventTrackOptions } from "./AnalyticsAdapter"

export class AnalyticsService {
  constructor(private analytics: AnalyticsAdapter) {}

  public static create(analyticsAdapter?: AnalyticsAdapter) {
    return new AnalyticsService(analyticsAdapter || AnalyticsAdapter.create())
  }

  public getAnalytics() {
    return this.analytics
  }

  public tagCurrentUser(user: SessionUser) {
    this.analytics.tagCurrentUser(user)
  }

  public pageView(
    pageName: string,
    eventCategory?: string,
    eventProperties?: EventTrackOptions["eventProperties"]
  ) {
    try {
      this.analytics.track(`view_${pageName}`, eventCategory || "view", {
        pageView: { title: pageName },
        eventProperties
      })
      this.analytics.track(`page_viewed`, eventCategory || "view", {
        pageView: { title: pageName },
        eventProperties: {
          ...eventProperties,
          page_name: pageName
        }
      })
    } catch (e) {
      console.warn(e)
    }
  }

  public viewElectionPage(eventProperties?: ViewElectionEventProperties) {
    return this.pageView("election_page", "Browsing", eventProperties)
  }

  public viewElectionCandidatePage(
    eventProperties?: ViewElectionCandidateEventProperties
  ) {
    return this.pageView("election_candidate_page", "Browsing", eventProperties)
  }

  public viewMyElectionsPage() {
    return this.pageView("my_elections_page", "Personalising")
  }

  public clickMyElections(eventProperties: ClickMyElectionsEventProperties) {
    return this.analytics.track("click_my_elections", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickDatasetExport(eventProperties: { page_name: string }) {
    return this.analytics.track("data_exported", "Content", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickMyGubernatorialLiveBlogCard(
    eventProperties: BaseElectionEventProperties
  ) {
    return this.analytics.track("click_gubernatorial_live_blog", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickStateDropdownItem(
    eventProperties: ClickStateDropdownItemEventProperties
  ) {
    return this.analytics.track("click_state_dropdown_item", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickElectionMap(eventProperties: ClickElectionMapEventProperties) {
    return this.analytics.track("click_election_map", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickHeadToHeadCandidate(eventProperties: ClickElectionCandidate) {
    return this.analytics.track(
      "click_view_head_to_head_candidate",
      "Browsing",
      {
        eventProperties: removeUndefined(eventProperties) as Record<
          string,
          string | number | boolean
        >
      }
    )
  }

  public clickElectionCandidate(eventProperties: ClickElectionCandidate) {
    return this.analytics.track("click_view_candidate", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickVotePerCandidate(
    eventProperties: Partial<CandidateCampaignInfo>
  ) {
    return this.analytics.track("click_view_vote_per_candidate", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickCandidateHistory(
    eventProperties: Partial<CandidateCampaignInfo>
  ) {
    return this.analytics.track(
      "click_view_candidate_history_campaign",
      "Browsing",
      {
        eventProperties: removeUndefined(eventProperties) as Record<
          string,
          string | number | boolean
        >
      }
    )
  }

  public clickElectionDominantPartyCandidate(
    eventProperties: ClickElectionDominantParty
  ) {
    return this.analytics.track(
      "click_view_dominant_party_candidate",
      "Browsing",
      {
        eventProperties: removeUndefined(eventProperties) as Record<
          string,
          string | number | boolean
        >
      }
    )
  }

  public clickViewAllElectionCandidates(
    eventProperties: ClickViewAllElectionCandidates
  ) {
    return this.analytics.track("click_view_all_candidates", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickNavigationTimelineYear(
    eventProperties: ClickNavigationTimelineYear
  ) {
    return this.analytics.track("click_navigate_timeline", "Browsing", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  /**
   * @deprecated
   * @param eventProperties
   */
  public clickSocialShareButton(eventProperties: SocialShareEventProps) {
    return this.analytics.track("click_social_share", "promotion", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickViewAllElectionPartiesForState(
    eventProperties: ClickViewAllElectionParties
  ) {
    return this.analytics.track(
      "click_view_all_parties_for_state",
      "Browsing",
      {
        eventProperties: removeUndefined(eventProperties) as Record<
          string,
          string | number | boolean
        >
      }
    )
  }

  public clickFollowElection(eventProperties: FollowElectionEventProperties) {
    return this.analytics.track("click_follow_election", "Personalising", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickSaveElectionPreferences(
    eventProperties: SaveElectionPreferencesEventProperties
  ) {
    return this.analytics.track(
      "click_save_election_preferences",
      "Personalising",
      {
        eventProperties: removeUndefined(eventProperties) as Record<
          string,
          string | number | boolean
        >
      }
    )
  }

  public clickArticleInFeed(
    eventProperties: ClickArticleInFeedEventProperties
  ) {
    return this.analytics.track("click_article_in_feed", "Content", {
      eventProperties: removeUndefined(eventProperties) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public clickArticleSearchResult(
    searchText: string,
    position: number,
    slug: string
  ) {
    this.analytics.track("web_article_search_result_click", "search", {
      eventProperties: {
        position,
        searchText,
        slug
      }
    })
  }

  public clickOnTopicArticle(
    position: number,
    topicSlug: string,
    articleSlug: string
  ) {
    this.analytics.track("web_topic_article_click", "home", {
      eventProperties: {
        position,
        topicSlug,
        articleSlug
      }
    })
  }

  public clickOnHomepageArticle(position: number, slug: string) {
    this.analytics.track("web_homepage_article_click", "home", {
      eventProperties: {
        position,
        slug
      }
    })
  }

  public initiateSearchFromHomepage(searchText: string) {
    this.analytics.track("web_initiate_search_from_homepage", "home", {
      eventProperties: {
        searchText
      }
    })
  }

  public clickOnTodaysArticle(slug: string) {
    this.analytics.track("web_click_on_todays_article", "home", {
      eventProperties: {
        slug
      }
    })
  }

  public clickOnNavbar(option: string) {
    this.analytics.track("web_v2_navbar_item_click", "navigation", {
      eventProperties: {
        option
      }
    })
  }

  public clickOnFooter(option: string) {
    this.analytics.track("web_v2_footer_item_click", "footer", {
      eventProperties: {
        option
      }
    })
  }

  public clearArticleSearch(searchText: string) {
    this.analytics.track("web_clear_article_search", "search", {
      eventProperties: {
        searchText
      }
    })
  }

  public searchForArticle(searchText: string) {
    this.analytics.track("web_search_article", "search", {
      eventProperties: {
        searchText
      }
    })
  }

  public loadMoreSearchResult(searchText: string, count: number) {
    this.analytics.track("web_load_more_search", "search", {
      eventProperties: {
        searchText,
        count
      }
    })
  }

  public dealsPrevPageClick(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("click_deals_prev", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsNextPageClick(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("click_deals_next", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsTableUpdated(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("update_deals_table", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsLimitChange(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("change_deals_limit", "content", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsScreenButtonClick(
    props: { destination: string } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_screen", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public clickCompanyProfileTab(
    props: { tab_name: string } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_company_profile_tab", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public copyCompanyProfileData(
    props: {
      section: CompanyProfileTrackableSection
      content_starting_point?: string // mixpanel has a 255 character limit per prop
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("copy_company_profile_section", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public viewManagementBioCompanyProfile(
    props: {
      slug: string
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("view_management_bio", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsExportButtonClick(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("click_export", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsModalExportButtonClick(
    props: EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_modal_export", "navigation", {
      eventProperties: {
        ...props
      }
    })
  }

  public dealsModalCancelClick() {
    this.analytics.track("click_modal_cancel", "navigation", {
      eventProperties: {}
    })
  }

  public dealsModalCloseClick() {
    this.analytics.track("click_modal_close", "navigation", {
      eventProperties: {}
    })
  }

  public dealsSortingChange(
    props: {
      screening_criteria: EventTrackOptions["eventProperties"]
      sort: string
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("update_deals_sort", "content", {
      eventProperties: {
        ...props
      }
    })
  }

  public clickSubscribe(
    props: {
      context: string
      type?: "link" | "button"
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track(`click_${ANALYTICS_SUBSCRIBE}`, "subscribe", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickFollowRaces(page: string) {
    this.analytics.track("click_follow_race_button", "promotion", {
      eventProperties: {
        context: ELECTIONS_BANNER,
        page
      }
    })
  }

  public clickEnergyPriceTracker() {
    this.analytics.track("click_energy_price_tracker", "promotion", {
      eventProperties: {}
    })
  }

  public clickIntelligenceSolutions() {
    this.analytics.track("click_data_solutions_banner", "promotion")
  }

  public clickSignup(
    props: {
      context: string
      type?: "link" | "button"
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_sign_up", "sign_up", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickSignOut(
    props: {
      context: string
      type?: "link" | "button"
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_sign_out", "navigation", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickSignin(
    props: {
      context: string
      type?: "link" | "button"
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_sign_in", "sign_in", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickDownloadMobileApp(OS: OperatingSystems) {
    if (typeof window === "undefined") return

    this.analytics.track("open_qr_code", "mobile_app_download", {
      eventProperties: {
        OS,
        event_label: `OS=${OS},location=${window.location.pathname}`
      }
    })
  }

  public trackLogin(platform: "apple" | "google" | "password") {
    this.analytics.track(`web_login_with_${platform}`, "authentication")
  }

  public trackLoginFailure(eventProperties: Record<string, any>) {
    this.analytics.track("web_login_failure", "authentication", {
      eventProperties
    })
  }

  public trackResetPassword(eventProperties: Record<string, any>) {
    this.analytics.track("web_reset_password", "authentication", {
      eventProperties
    })
  }

  public trackResetPasswordSuccess(eventProperties: Record<string, any>) {
    this.analytics.track("web_reset_password_successful", "authentication", {
      eventProperties
    })
  }

  public trackResetPasswordFailure(eventProperties: Record<string, any>) {
    this.analytics.track("web_reset_password_failure", "authentication", {
      eventProperties
    })
  }

  public trackSignup(platform: "apple" | "google" | "password") {
    this.analytics.track(`web_signup_with_${platform}`, "authentication")
  }

  public trackSignupFailure(eventProperties: Record<string, any>) {
    this.analytics.track("web_signup_failure", "authentication", {
      eventProperties
    })
  }

  public trackSignupSuccess(platform: "apple" | "google" | "password") {
    this.analytics.track(`web_signup_with_${platform}`, "authentication")
  }

  public trackSubscriptionSuccess(planCode: string) {
    this.analytics.track(`subscription_success_page`, "subscribe", {
      eventProperties: { plan: planCode }
    })
  }

  public trackSignout() {
    this.analytics.track("web_signout", "authentication")
  }

  public reset() {
    this.analytics.reset()
  }

  public tagVisitor() {
    this.analytics.tagVisitor({ memberStatus: "visitor" })
  }

  public clickNavItem(
    name: string,
    props: EventTrackOptions["eventProperties"] = {}
  ) {
    /**
     * @deprecated
     *
     * We use "menu_item_clicked" now
     */
    this.analytics.track(`click_${name}`, "navigation", {
      eventProperties: removeUndefined(props)
    })
    this.analytics.track(`menu_item_clicked`, "navigation", {
      eventProperties: removeUndefined({ ...props, value: name })
    })
  }

  public clickSubscriptionMarketingSubscribeButton() {
    this.analytics.track(`click_${ANALYTICS_SUBSCRIBE_BUTTON}`, "navigation", {
      eventProperties: { context: "subscription-marketing" }
    })
  }

  public seesBankTransferPopup() {
    this.analytics.track("sees_bank_transfer_popup", "subscribe")
  }

  public seesFailedTransactionPopup() {
    this.analytics.track("sees_failed_transaction_popup", "subscribe")
  }

  public seesFAQSection(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("sees_faq", "support", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickBankTransfer(props: EventTrackOptions["eventProperties"] = {}) {
    this.analytics.track("click_bank_transfer", "subscribe", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickCorporateQuote(
    props: {
      type?: "get_licence" | "upgrade_licence"
    } & EventTrackOptions["eventProperties"] = {}
  ) {
    this.analytics.track("licence_clicked", "subscribe", {
      eventProperties: removeUndefined(props)
    })
  }

  public clickGiftSubscription(
    props?: {
      context: string
      type: "link" | "button"
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("click_gift_subscription", "subscribe", {
      eventProperties: removeUndefined(props ?? {})
    })
  }

  public seePaywall(props: EventTrackOptions["eventProperties"] = {}) {
    this.analytics.track("article_paywall", "reading", {
      eventProperties: removeUndefined(props)
    })
  }

  public viewArticle(props: EventTrackOptions["eventProperties"] = {}) {
    this.analytics.track("article_view", "reading", {
      eventProperties: removeUndefined(props)
    })
  }

  public readArticle(
    props?: {
      time_spent_reading_sec: number
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("article_read", "reading", {
      eventProperties: removeUndefined(props ?? {})
    })
  }

  public finishArticle(
    props?: {
      time_spent_reading_sec: number
    } & EventTrackOptions["eventProperties"]
  ) {
    this.analytics.track("article_finish", "reading", {
      eventProperties: removeUndefined(props ?? {})
    })
  }

  public articleContentClicked(props: {
    label: string
    url: string
    type: "link"
  }) {
    this.analytics.track("article_content_clicked", "reading", {
      eventProperties: removeUndefined(props)
    })
  }

  public shareClicked(props: SocialShareEventProps) {
    return this.analytics.track("share_clicked", "promotion", {
      eventProperties: removeUndefined(props) as Record<
        string,
        string | number | boolean
      >
    })
  }

  public articleClicked(props: EventTrackOptions["eventProperties"] = {}) {
    return this.analytics.track("article_clicked", "navigation", {
      eventProperties: removeUndefined(props)
    })
  }

  public trackProfileUpdate(
    props: AnalyticsProfileUpdateProps & EventTrackOptions["eventProperties"]
  ) {
    return this.analytics.track("submit_profile", "profile", {
      eventProperties: removeUndefined(props)
    })
  }

  public trackProfileClose(
    props: Omit<AnalyticsProfileUpdateProps, "state"> &
      EventTrackOptions["eventProperties"]
  ) {
    return this.analytics.track("close_profile", "profile", {
      eventProperties: removeUndefined(props)
    })
  }

  public trackProfileRequest(
    props: Omit<AnalyticsProfileUpdateProps, "state"> &
      EventTrackOptions["eventProperties"]
  ) {
    return this.analytics.track("profile_request", "profile", {
      eventProperties: removeUndefined(props)
    })
  }

  public trackFlourishInteraction(props: {
    action:
      | "mouse_enter"
      | "mouse_leave"
      | "click"
      | "key_down"
      | "play"
      | "pause"
      | "slide_change"
    template_id: number
    visualisation_id: number
    story_id?: number
    slide_index?: number
    slide_count?: number
  }) {
    const type = props.story_id ? `story` : "visualisation"
    const story_url = props.story_id
      ? `https://app.flourish.studio/story/${props.story_id}`
      : undefined
    const visualisation_url = `https://app.flourish.studio/visualisation/${props.visualisation_id}`
    const template_url = `https://app.flourish.studio/${props.template_id}`

    return this.analytics.track("flourish_interaction", "article", {
      eventProperties: removeUndefined({
        ...props,
        type,
        story_url,
        visualisation_url,
        template_url
      }) as EventTrackOptions["eventProperties"]
    })
  }

  public updateDealsCount(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("update_deals_count", "count", {
      eventProperties: {
        ...props
      }
    })
  }

  public screenDeals(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("run_screen", "screen_deals", {
      eventProperties: {
        ...props
      }
    })
  }

  public cancelScreen(props: EventTrackOptions["eventProperties"]) {
    this.analytics.track("cancel_screener", "cancel_screen", {
      eventProperties: {
        ...props
      }
    })
  }
}
