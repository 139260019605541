import { IconProps } from "@/src/sb/business-layer/types"

const MobileNavSettings = (props: IconProps) => (
  <svg
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.85694H15.0857C14.8153 3.52532 13.6445 2.56836 12.2857 2.56836C10.9269 2.56836 9.75611 3.52532 9.48571 4.85694H2V5.9998H9.48571C9.75611 7.33141 10.9269 8.28837 12.2857 8.28837C13.6445 8.28837 14.8153 7.33141 15.0857 5.9998H18V4.85694ZM12.2912 7.14357C11.3444 7.14357 10.5769 6.37605 10.5769 5.42928C10.5769 4.48251 11.3444 3.71499 12.2912 3.71499C13.238 3.71499 14.0055 4.48251 14.0055 5.42928C14.0055 6.37605 13.238 7.14357 12.2912 7.14357ZM2 15.144H4.91429C5.18468 16.4756 6.3555 17.4326 7.71429 17.4326C9.07308 17.4326 10.2439 16.4756 10.5143 15.144H18V14.0012H10.5143C10.2439 12.6696 9.07308 11.7126 7.71429 11.7126C6.3555 11.7126 5.18468 12.6696 4.91429 14.0012H2V15.144ZM6 14.5711C6 13.6243 6.76751 12.8568 7.71429 12.8568C8.66106 12.8568 9.42857 13.6243 9.42857 14.5711C9.42857 15.5179 8.66106 16.2854 7.71429 16.2854C6.76751 16.2854 6 15.5179 6 14.5711Z"
      fill={props.color ?? "currentColor"}
    />
  </svg>
)

export default MobileNavSettings
