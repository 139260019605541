import resolveConfig from "tailwindcss/resolveConfig"

import tailwindConfig from "../../tailwind.config"

export const SB_API_URL = // see process.env.CI https://vercel.com/docs/concepts/projects/environment-variables#system-environment-variables
  process.env.CI && process.env.NEXT_PUBLIC_SB_API_BUILD_URL
    ? process.env.NEXT_PUBLIC_SB_API_BUILD_URL
    : process.env.NEXT_PUBLIC_SB_API_URL || ""
export const HOSTNAME = process.env.VERCEL_URL || ""
export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL_OVERRIDE ||
  (HOSTNAME ? `https://${HOSTNAME}` : "")
export const API_BASE_URL = `${SB_API_URL}/api/v2`
export const API_BASE_URL_V3 = `${SB_API_URL}/api/v3`
export const AXIOS_RETRY_LIMIT =
  parseInt(process.env.AXIOS_RETRY_LIMIT as string) || 5
export const RATE_LIMIT = process.env.RATE_LIMIT
export const RATE_LIMIT_MAX_RPS =
  parseInt(process.env.RATE_LIMIT_MAX_RPS as string) || 3
export const ANALYTICS_PLATFORM_TYPE =
  process.env.NEXT_PUBLIC_ANALYTICS_PLATFORM_TYPE || "web"

export const IS_PREVIEW_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
export const IS_PRODUCTION_ENVIRONMENT =
  process.env.NEXT_PUBLIC_STEARS_ENV === "prod"

export const SITEMAP_MAX_PAGES =
  parseInt(process.env.SITEMAP_MAX_PAGES as string) || 14
export const SITEMAP_MAX_ARTICLES =
  parseInt(process.env.SITEMAP_MAX_ARTICLES as string) || 100

export const REQUEST_TIMEOUT =
  parseInt(process.env.NEXT_PUBLIC_REQUEST_TIMEOUT_MS as string) || 10000

export const ENABLE_DEBUG_MODE =
  process.env.NEXT_PUBLIC_ENABLE_DEBUG_MODE === "true"
export const MIXPANEL_ENABLE_DEBUG = ENABLE_DEBUG_MODE

export const MIXPANEL_IGNORE_DNT =
  process.env.NEXT_PUBLIC_MIXPANEL_IGNORE_DNT === "true"

export const TOPICS_TO_IGNORE = (
  process.env.NEXT_PUBLIC_TOPICS_TO_IGNORE || ""
).split(",")

export const MENU_ITEM_TO_IGNORE = (
  process.env.NEXT_PUBLIC_MENU_ITEM_TO_IGNORE || ""
).split(",")

export const TAILWIND_CONFIG = resolveConfig(tailwindConfig)

const portraitTabletMaxWidth = (
  TAILWIND_CONFIG.theme?.screens as Record<string, any>
)?.["portrait_tablet"]?.max?.replace(/\D/gm, "")
export const PORTRAIT_TABLET_MAX_WIDTH = portraitTabletMaxWidth
  ? Number(portraitTabletMaxWidth)
  : 1024

export const NAVBAR_TOPICS_TO_HIDE_WIDTH = 1280
export const HUBSPOT_ACCOUNT =
  process.env.NEXT_PUBLIC_HUBSPOT_ACCOUNT || "8143058"
export const HUBSPOT_API_KEY = process.env.HUBSPOT_API_KEY
export const GROWTH_BOOK_KEY = process.env.NEXT_PUBLIC_GROWTH_BOOK_KEY
