import { GRAPHQL_ENDPOINT } from "@/src/sb/constants/configs"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { useSession } from "next-auth/react"
import { ComponentPropsWithoutRef, useMemo } from "react"

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT
})

const ApolloClientIntegration = ({
  children
}: ComponentPropsWithoutRef<any>) => {
  const { data: session } = useSession()
  const token = (session?.user as any)?.accessToken

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
          }
        }
      }),
    [token]
  )

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
      }),
    [authLink]
  )

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloClientIntegration
