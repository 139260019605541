export const pages = {
  signin: "/accounts/signin/",
  signup: "/accounts/signup/",
  waitForActivate: "/accounts/wait-for-activate/",
  landingPage: "/",
  signupSuccess: "/accounts/signup-success/",
  profileUpdate: "/accounts/update/",
  socialRegistrationSuccess: "/accounts/social-register-success/",
  forgotPassword: "/accounts/password-reset-start/",
  passwordResetCheckMail: "/accounts/password-reset-check-mail/",
  resetPasswordOTPExpired: "/accounts/password-reset-expired/",
  passwordResetSuccess: "/accounts/password-reset-success/",
  search: "/search/",
  dashboard: "/accounts/dashboard/",
  admin: "/accounts/licence/team/",
  subscriptions: "/subscriptions/",
  licenceIntermediary: "/subscriptions/licence/redirect/",
  giftSubscription: "/accounts/gift/",
  premium: "/premium",
  signout: "/v2/accounts/signout/",
  termsAndConditions: "/terms-and-conditions/",
  getQuoteURL: "https://share.hsforms.com/13ObtRbV5RMS-Kut-3vvj4g4uj82",
  privacyPolicy: "/privacy-policy/",
  home: "/search/",
  africaFXMonitor: "/data/africa-fx-monitor/african-currencies/",
  elections: "/elections",
  deals: "/deals",
  topic: (topic: string) => `/topics/${topic}/`,
  article: (topic: string) => `/article/${topic}/`
}

export default pages
