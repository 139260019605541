import { DialingCodeSelectOption } from "@/src/common/components/Select"
import countries from "@/src/common/data-layer/datasources/countries.json"
import { ENERGY_PRICE_TRACKER } from "@/src/elections/business-layer/constants"
import { ENERGY_PRICE_TRACKER_OBJECT } from "@/src/sb/constants"
import { sortBy } from "lodash"

// Analytics
export const ANALYTICS_SUBSCRIBE_BUTTON = "subscribe_button"
export const ANALYTICS_SUBSCRIBE = "subscribe"

// Miscellaneous
export const PAGE_PREFIX_TO_HIDE_FRESHWORKS = [
  "/elections",
  "/premium/article",
  "/article",
  "/embeddable",
  "/deals",
  "/screener",
  "/companies"
]

export const SELECT_INPUT_OTHER_VALUE = "Other"

export const JOB_TITLE_OPTIONS = sortBy(
  [
    { value: "Analyst", label: "Analyst" },
    { value: "Associate", label: "Associate" },
    { value: "Consultant", label: "Consultant" },
    { value: "Portfolio Manager", label: "Portfolio Manager" },
    { value: "Vice President", label: "Vice President" },
    { value: "Director", label: "Director" },
    {
      value: "Chief Investment Officer",
      label: "Chief Investment Officer"
    },
    {
      value: "Managing Director/CEO",
      label: "Managing Director/CEO"
    },
    { value: "Researcher", label: "Researcher" }
  ],
  (jobTitle) => jobTitle.label
).concat({ value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE })

export const SENIORITY_OPTIONS = [
  { value: "C-Level Executive", label: "C-Level Executive" },
  { value: "Vice President", label: "Vice President" },
  { value: "Director", label: "Director" },
  { value: "Head of Department", label: "Head of Department" },
  { value: "Manager", label: "Manager" },
  { value: "Senior Level", label: "Senior Level" },
  { value: "Mid Level", label: "Mid Level" },
  { value: "Entry Level", label: "Entry Level" },
  { value: "Student", label: "Student" },
  { value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE }
]

export const INDUSTRY_OPTIONS = sortBy(
  [
    { value: "Asset Management", label: "Asset Management" },
    { value: "Banking & Finance", label: "Banking & Finance" },
    { value: "Consulting", label: "Consulting" },
    { value: "Equity Markets", label: "Equity Markets" },
    { value: "International Development", label: "International Development" },
    { value: "Family Office", label: "Family Office" },
    { value: "FinTech", label: "FinTech" },
    { value: "FMCG", label: "FMCG" },
    { value: "Government", label: "Government" },
    { value: "Hedge Fund", label: "Hedge Fund" },
    { value: "Investment Banking", label: "Investment Banking" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Market Research", label: "Market Research" },
    { value: "Mutual Fund", label: "Mutual Fund" },
    { value: "Pension Fund", label: "Pension Fund" },
    { value: "Private Equity", label: "Private Equity" },
    { value: "Public Debt", label: "Public Debt" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Policy", label: "Policy" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Insurance", label: "Insurance" }
  ],
  (jobTitle) => jobTitle.label
).concat({ value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE })

export const COMPANY_SIZE_OPTIONS = [
  { value: "1 - 5", label: "1 - 5" },
  { value: "5 - 25", label: "5 - 25" },
  { value: "25 - 50", label: "25 - 50" },
  { value: "50 - 100", label: "50 - 100" },
  { value: "100 - 500", label: "100 - 500" },
  { value: "500 - 1000", label: "500 - 1000" },
  { value: "1000+", label: "1000+" }
]

export const REFERRAL_SOURCE_OPTIONS = sortBy(
  [
    { value: "Google Search", label: "Google Search" },
    { value: "Twitter", label: "Twitter" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Email", label: "Email" },
    { value: "Online Event", label: "Online Event" },
    { value: "Offline Event", label: "Offline Event" },
    {
      value: "Television or Radio",
      label: "Television or Radio"
    },
    {
      value: "Professional network",
      label: "Professional network"
    }
  ],
  (jobTitle) => jobTitle.label
).concat({ value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE })

export const TOP_PREFERENCE_COUNTRY: Array<string> = [
  "NG",
  "GB",
  "US",
  "ZA",
  "KE"
]
const COUNTRY_CODE_OPTIONS_TOP: Array<DialingCodeSelectOption> = []
const COUNTRY_CODE_OPTIONS_BOTTOM: Array<DialingCodeSelectOption> = []

for (const country of countries as Array<{
  code: string
  dial_code: number
  flag: string
  name: string
}>) {
  const countryCode: DialingCodeSelectOption = {
    value: `+${country.dial_code}`,
    label: `+${country.dial_code}`,
    ...country
  }
  if (TOP_PREFERENCE_COUNTRY.includes(country.code)) {
    COUNTRY_CODE_OPTIONS_TOP.push(countryCode)

    continue
  }

  COUNTRY_CODE_OPTIONS_BOTTOM.push(countryCode)
}

export const COUNTRY_CODE_OPTIONS: Array<DialingCodeSelectOption> =
  TOP_PREFERENCE_COUNTRY.reduce(
    (options: Array<DialingCodeSelectOption>, countryCode) => {
      const option = COUNTRY_CODE_OPTIONS_TOP.find(
        (country) => country.code === countryCode
      )

      if (!option) return options

      return [...options, option]
    },
    []
  ).concat(COUNTRY_CODE_OPTIONS_BOTTOM)

export const SOCIAL_MEDIA_SIGNUP_KEY = "media"

export const PAGE_INTENT: Record<string, string> = {
  [ENERGY_PRICE_TRACKER_OBJECT.href]: ENERGY_PRICE_TRACKER
}

export const ARTICLE_PATH_MATCHES = [
  /^\/premium\/article\/.+/gi,
  /^\/article\/.+/gi
]

export const DATE_FORMAT = {
  monthDayYear: "MMMM d, y",
  shortMonthDayYear: "MMM d, y",
  dayMonthYear: "d MMMM, y"
}
