import { IconProps } from "@/src/sb/business-layer/types"

const MobileNavSignout = (props: IconProps) => (
  <svg
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9996 3.68741L13.3843 4.75193C16.0374 6.28369 17.3309 9.40644 16.538 12.3656C15.7451 15.3247 13.0635 17.3823 10 17.3823C6.93648 17.3823 4.25491 15.3247 3.46202 12.3656C2.66912 9.40644 3.96261 6.28369 6.61569 4.75193L6.00036 3.68741C2.8649 5.49767 1.33623 9.1882 2.27329 12.6854C3.21035 16.1825 6.37948 18.6143 10 18.6143C13.6205 18.6143 16.7896 16.1825 17.7267 12.6854C18.6638 9.1882 17.1351 5.49767 13.9996 3.68741ZM9.38577 1.38672H10.6164V10.0013H9.38577V1.38672Z"
      fill={props.color ?? "currentColor"}
    />
  </svg>
)

export default MobileNavSignout
