import { HUBSPOT_ACCOUNT } from "@/src/common/config"
import CommonAPIInstance from "@/src/common/data-layer/datasources/CommonAPI"
import { HUBSPOT_VISITOR_IDENTIFICATION_API_URL } from "@/src/common/endpoints"
import { LoggedInUser } from "@/src/sb/business-layer/types"
import { useUser } from "@/src/sb/hooks/useUser"
import * as Sentry from "@sentry/nextjs"
import Script from "next/script"
import React, { useEffect, useState } from "react"

type currentSessionUser = Pick<
  LoggedInUser["data"],
  "email" | "firstName" | "lastName"
>

const HubSpotChatWidget = () => {
  const user = useUser()
  const [loadHubSpotWidget, setLoadHubSpotWidget] = useState(false)

  const identifyVisitor = async (payload: currentSessionUser) => {
    if (!payload) {
      setLoadHubSpotWidget(true)

      return
    }

    try {
      const tokenRequest = await CommonAPIInstance.visitorIdentification(
        HUBSPOT_VISITOR_IDENTIFICATION_API_URL,
        payload
      )

      ;(window as any).hsConversationsSettings = {
        ...(window as any).hsConversationsSettings,
        identificationEmail: payload.email,
        identificationToken: tokenRequest.data.token
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    setLoadHubSpotWidget(true)
  }

  useEffect(() => {
    const windowAsAny = window as any
    windowAsAny.hsConversationsSettings = {
      ...windowAsAny.hsConversationsSettings,
      loadImmediately: false
    }

    const onConversationsAPIReady = () => {
      windowAsAny.HubSpotConversations?.widget?.load({ widgetOpen: false })
    }

    if (windowAsAny.HubSpotConversations) {
      onConversationsAPIReady()
    } else {
      windowAsAny.hsConversationsOnReady = [onConversationsAPIReady]
    }
  }, [])

  useEffect(() => {
    if (user.loading) return

    identifyVisitor(user?.data as currentSessionUser)
  }, [user])

  return (
    <>
      {loadHubSpotWidget ? (
        <Script
          id="hsScriptLoader"
          src={`//js-na1.hs-scripts.com/${HUBSPOT_ACCOUNT}.js`}
        />
      ) : null}
    </>
  )
}

export default HubSpotChatWidget
