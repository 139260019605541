import { ApolloClientIntegration } from "@/src/common/components/ApolloClientIntegration"
import { GrowthBookInstance } from "@/src/common/data-layer"
import ToastProvider from "@/src/common/hooks/useToast/ToastProvider"
import { GrowthBookProvider } from "@growthbook/growthbook-react"
import { SessionProvider } from "next-auth/react"
import type { AppProps } from "next/app"
import { Mulish } from "next/font/google"
import NextNProgress from "nextjs-progressbar"
import { useEffect, useState } from "react"

// import "react-loading-skeleton/dist/skeleton.css"
import { AppThirdParties } from "../common/components/AppThirdParties"
import "../common/styles/globals.css"
import ThemeCtx from "../contexts/ThemeCtx"
import { AnalyticsProvider } from "../sb/components/Analytics"
import { SEO } from "../sb/components/SEO"

const mulish = Mulish({
  subsets: ["latin"],
  variable: "--font-mulish"
})

export default function App({
  Component,
  router,
  pageProps: { session, ...pageProps }
}: AppProps) {
  const [theme, setTheme] = useState<"dark" | "light">("dark")

  useEffect(() => {
    const persistedThemeValue = localStorage.getItem("theme") as
      | "dark"
      | "light"

    if (persistedThemeValue && persistedThemeValue !== theme)
      setTheme(persistedThemeValue)
    if (persistedThemeValue === null) localStorage.setItem("theme", "dark")
  }, [])

  const updateGrowthBookURL = async () => {
    await GrowthBookInstance.setURL(window.location.href)
  }

  useEffect(() => {
    GrowthBookInstance.loadFeatures()

    // Subscribe to route change events and update GrowthBook
    router.events.on("routeChangeComplete", updateGrowthBookURL)
    return () => router.events.off("routeChangeComplete", updateGrowthBookURL)
  }, [])

  return (
    <>
      <NextNProgress
        color="#fff"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        options={{ showSpinner: false }}
        showOnShallow={true}
      />

      <style jsx global>{`
        * {
          --font-mulish: ${mulish.style.fontFamily};
        }
      `}</style>

      <SessionProvider session={session}>
        <ApolloClientIntegration>
          <GrowthBookProvider growthbook={GrowthBookInstance}>
            <ThemeCtx.Provider value={{ theme }}>
              <AnalyticsProvider>
                {router.pathname.indexOf("/elections") === -1 ? (
                  <SEO {...(pageProps?.layoutProps?.seo ?? pageProps.seo)} />
                ) : null}
                <ToastProvider>
                  <Component {...pageProps} />

                  {/*Component tree Third party apps/integration gets initialized/configured globally in AppThirdParties component*/}
                  <AppThirdParties />
                </ToastProvider>
              </AnalyticsProvider>
            </ThemeCtx.Provider>
          </GrowthBookProvider>
        </ApolloClientIntegration>
      </SessionProvider>
    </>
  )
}
