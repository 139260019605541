import { EventTrackOptions } from "@/src/sb/components/Analytics/AnalyticsAdapter"
import { AnalyticsService } from "@/src/sb/components/Analytics/AnalyticsService"
import ArrowTopLeft from "@/src/sb/components/icon/ArrowTopLeft"
import MobileNavArrow from "@/src/sb/components/icon/MobileNavArrow"
import MobileNavSettings from "@/src/sb/components/icon/MobileNavSetting"
import MobileNavSignout from "@/src/sb/components/icon/MobileNavSignout"
import User from "@/src/sb/components/icon/User"
import {
  UNCOMPLETED_PROFILE_DEMO_FORM_URL,
  V2_FEATURE_TOGGLES
} from "@/src/sb/constants/configs"
import { B2C_SUNSET } from "@/src/sb/constants/featureFlags"

import { pages } from "./pageLinks"

type MenuItemLink = {
  icon?: JSX.Element | string
  href: string
  label: string
  canHaveNextQueryParam?: boolean
  handleClick?: (
    analytics: AnalyticsService,
    props: {
      context: string
      type?: "link" | "button"
    } & EventTrackOptions["eventProperties"]
  ) => void
}

export type MenuItemOption = MenuItemLink

export const topics: MenuItemOption[] = [
  {
    icon: "/assets/icons/navItems/economy.svg",
    href: "/economy",
    label: "Econony"
  },
  {
    icon: "/assets/icons/navItems/business.svg",
    href: "/business",
    label: "Business"
  },
  {
    icon: "/assets/icons/navItems/government.svg",
    href: "/government",
    label: "Government"
  },
  {
    icon: "/assets/icons/navItems/technology.svg",
    href: "/technology",
    label: "Technology"
  },
  {
    icon: "/assets/icons/navItems/free-to-read.svg",
    href: "/free-to-read",
    label: "Free to read"
  }
]

export const authOptions: Record<string, MenuItemLink> = {
  signin: {
    icon: <User size={14} />,
    href: pages.signin,
    label: "Sign in",
    canHaveNextQueryParam: true,
    handleClick: (analytics, props) => analytics?.clickSignin(props)
  },
  subscribe: {
    icon: <ArrowTopLeft size={14} />,
    href: V2_FEATURE_TOGGLES.has(B2C_SUNSET)
      ? UNCOMPLETED_PROFILE_DEMO_FORM_URL
      : pages.subscriptions,
    label: V2_FEATURE_TOGGLES.has(B2C_SUNSET) ? "Request Demo" : "Subscribe",
    canHaveNextQueryParam: true,
    handleClick: (analytics, props) =>
      V2_FEATURE_TOGGLES.has(B2C_SUNSET)
        ? analytics?.clickCorporateQuote({ type: "get_licence" })
        : analytics?.clickSubscribe(props)
  },
  dashboard: {
    icon: <MobileNavSettings size={20} />,
    href: pages.dashboard,
    label: "My Profile"
  },
  admin: {
    icon: <MobileNavArrow size={20} />,
    href: pages.admin,
    label: "Admin"
  },
  upgrade: {
    icon: <ArrowTopLeft size={14} />,
    href: pages.subscriptions,
    label: "Upgrade",
    canHaveNextQueryParam: true,
    handleClick: (analytics, props) => analytics?.clickSubscribe(props)
  },
  saved: {
    icon: "/assets/icons/navItems/bookmark.svg",
    href: "/saved",
    label: "Saved"
  },
  signout: {
    icon: <MobileNavSignout />,
    href: pages.signout,
    label: "Sign out",
    canHaveNextQueryParam: true,
    handleClick: (analytics, props) => analytics?.clickSignOut(props)
  }
}

const anonymous: MenuItemOption[] = [authOptions.signin, authOptions.subscribe]

const loggedIn: MenuItemOption[] = [authOptions.dashboard, authOptions.signout]

const subscriber: MenuItemOption[] = [
  authOptions.dashboard,
  authOptions.signout
]

export const accounts = {
  anonymous,
  loggedIn,
  subscriber
}
