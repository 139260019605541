import { SB_MEMBER_PROFILE_API_URL } from "@/src/sb/business-layer/config"
import { AnonymousUser, LoggedInUser } from "@/src/sb/business-layer/types"
import { useSession } from "next-auth/react"
import useSWR from "swr-v1"

import ExternalHTTPInstance from "../../common/data-layer/datasources/ExternalHTTP"
import { SessionUser } from "../core/models/auth"

export const useUser = (): SessionUser => {
  const session = useSession() as any
  let user: LoggedInUser["data"] | AnonymousUser["data"] =
    session.data?.user?.data
  const userId = user?.user_id
  const token = (session?.data?.user as any)?.accessToken

  /**
   * Profile fetch: To compliment missing corporate licence information on
   * next-auth jwt user session
   *
   * TODO: Is there a way we can make do without this complimentary profile fetch
   */
  const { data: userProfile } = useSWR(
    userId && token ? [SB_MEMBER_PROFILE_API_URL, token, userId] : null,
    ExternalHTTPInstance.get.bind<
      (url: string, token?: string) => Promise<LoggedInUser["data"]>
    >(ExternalHTTPInstance)
  )

  if (user) {
    /**
     * user is spread last to make sure next-auth jwt payload takes precedence
     * when prop is provided
     */
    user = { ...userProfile, ...user }
    const [firstName, lastName] = user.nickname?.split(" ") ?? []
    user.firstName = firstName
    user.lastName = lastName
    const roles = user.roles as string[]
    const isSubscriber = roles?.includes("premium")
    const isAdmin = roles?.includes("licence_admin")

    return {
      data: { ...user, isAdmin },
      userType: isSubscriber ? "subscriber" : "loggedIn",
      loading: false
    }
  }

  return {
    userType: "anonymous",
    data: user,
    loading: session.data === undefined
  }
}
