import { IconProps } from "@/src/sb/business-layer/types"

const ArrowTopLeft = (props: IconProps) => (
  <svg
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M5.87857 5.26932C5.61822 5.00897 5.19611 5.00897 4.93576 5.26932C4.67541 5.52967 4.67541 5.95178 4.93576 6.21213L10.5926 11.869C10.853 12.1293 11.2751 12.1293 11.5354 11.869C11.7958 11.6086 11.7958 11.1865 11.5354 10.9262L5.87857 5.26932Z"
      fill={props.color ?? "currentColor"}
    />
    <path
      d="M5.60238 10.9259C5.60238 11.2941 5.3039 11.5926 4.93571 11.5926C4.56752 11.5926 4.26904 11.2941 4.26904 10.9259V5.26908C4.26904 4.91215 4.55016 4.61855 4.90675 4.60304L10.3279 4.36734C10.6957 4.35135 11.0069 4.63658 11.0229 5.00442C11.0389 5.37226 10.7537 5.68342 10.3858 5.69942L5.60238 5.90739V10.9259Z"
      fill={props.color ?? "currentColor"}
    />
  </svg>
)

export default ArrowTopLeft
