import axios, { AxiosError, AxiosInstance } from "axios"
import AxiosRateLimit from "axios-rate-limit"
import AxiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry"

import {
  API_BASE_URL,
  AXIOS_RETRY_LIMIT,
  RATE_LIMIT,
  RATE_LIMIT_MAX_RPS,
  REQUEST_TIMEOUT
} from "./config"

const retryCondition = (e: AxiosError) => {
  return isNetworkOrIdempotentRequestError(e) || e.response?.status === 429
}

const retryDelay = (count: number, error: AxiosError) => {
  if (error.response?.status === 429) {
    const delaySecs = parseInt(error.response?.headers["retry-after"])
    if (delaySecs) {
      console.warn(`Hit rate limit. Retrying after ${delaySecs} secs...`)
      return delaySecs * 1000
    }
  }
  return AxiosRetry.exponentialDelay(count)
}

export class HttpService {
  /**
   * AxiosInstance
   */
  public ax: AxiosInstance

  constructor() {
    this.ax = axios.create({
      baseURL: API_BASE_URL,
      timeout: REQUEST_TIMEOUT
    })

    if (RATE_LIMIT === "true") {
      console.warn(
        `Will apply rate limit to requests - ${RATE_LIMIT_MAX_RPS} per second`
      )
      this.ax = AxiosRateLimit(this.ax, {
        maxRPS: RATE_LIMIT_MAX_RPS
      })
    }

    AxiosRetry(this.ax, {
      retries: AXIOS_RETRY_LIMIT,
      retryDelay,
      retryCondition
    })
  }

  setAuthorization(token: string): void {
    this.ax.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  deleteAuthorization(): void {
    delete this.ax.defaults.headers.common.Authorization
  }
}

export const HttpServiceInstance = new HttpService()
const axiosInstance = HttpServiceInstance.ax

export default axiosInstance
