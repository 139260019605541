import React, { ReactNode, createContext, useContext } from "react"

import useToast from "./useToast"

type ToastContextType = (type: "success" | "error", message: string) => void

const ToastContext = createContext<ToastContextType | null>(null)

export const useToastContext = () => {
  const context = useContext(ToastContext)
  if (context === null) {
    throw new Error("useToastContext must be used in a ToastProvider")
  }
  return context
}

interface IToastProviderProps {
  children: ReactNode
}

const ToastProvider: React.FC<IToastProviderProps> = ({ children }) => {
  const { showToastMessage, toastComponent } = useToast()

  return (
    <ToastContext.Provider value={showToastMessage}>
      {children}
      {toastComponent}
    </ToastContext.Provider>
  )
}

export default ToastProvider
