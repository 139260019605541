// v2 endpoints
export const ARTICLES_ENDPOINT = "/content/article-summaries/"
export const DATASETS_ENDPOINT = "/content/datasets/"
export const TODAYS_HIGHLIGHT = "/content/highlights/"
export const SEARCH_ARTICLES_ENDPOINT = "/content/search/"
export const FETCH_TOPICS_ENDPOINT = "/content/topics/"
export const NAVIGATION_MENU_ENDPOINT = "/content/navigations/"

export const LOGGED_IN_ENDPOINT = "/auth/logged-in/"
export const SIGNUP_ENDPOINT = "/auth/signup/"

// process.env.NEXT_PUBLIC_NEXTAUTH_URL helps to make sure local development still works right when set
export const SIGNIN_ENDPOINT = `${
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : process.env.NEXT_PUBLIC_NEXTAUTH_URL
}/api/auth/callback/credentials/`
export const INITIATE_PASSWORD_RESET_ENDPOINT =
  "/auth/reset-password-otp/?mode=link"
export const VERIFY_RESET_OTP_ENDPOINT = "/auth/otp/verify/"
export const RESET_PASSWORD_ENDPOINT = "/auth/password-reset/"
export const ACTIVATE_ACCOUNT_ENDPOINT = "/auth/activations/"
export const RESEND_ACTIVATION_LINK_ENDPOINT = "/auth/activation-links/"
export const SOCIAL_AUTH_LOGIN_ENDPOINT = "/auth/social/token/"
export const API_CSRF_TOKEN_ENDPOINT = "/csrf/"
export const API_REFRESH_TOKEN_ENDPOINT = "/auth/token/refresh/"
// Legacy routes
export const SB_LEGACY_LOGIN_URL = "/accounts/signin/"
export const SB_LEGACY_SIGNOUT_URL = "/accounts/signout/"
