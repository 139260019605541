import { SidebarMenuItemV2 } from "@/src/sb/business-layer/types"

const SidebarMenuItemsMockV2 = (): {
  [key: string]: Array<SidebarMenuItemV2>
} => {
  return {
    "open data": [
      {
        id: 1000001,
        name: "African Democracy",
        destination: "#",
        slug: "african-democracy",
        isFilterItem: false,
        isSidebarItem: true,
        children: [
          {
            id: 10000020,
            name: "Election Tracker",
            destination: "#",
            slug: "election-tracker",
            isFilterItem: false,
            isSidebarItem: true,
            children: [
              {
                id: 100000010,
                name: "Upcoming Elections",
                destination: "/open-data/elections/upcoming-african-elections/",
                slug: "upcoming--elections",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 100000020,
                name: "Recent Elections",
                destination: "/open-data/elections/recent-african-elections/",
                slug: "recent-elections",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 100000030,
                name: "Context Maps",
                destination: "/open-data/elections/election-context-maps/",
                slug: "context-maps",
                isFilterItem: false,
                isSidebarItem: true
              }
            ]
          },
          {
            id: 10000040,
            name: "Democracy Data",
            destination: "#",
            slug: "democracy-data",
            isFilterItem: false,
            isSidebarItem: true,
            children: [
              {
                id: 100000040,
                name: "Conflict & Coups",
                destination:
                  "/open-data/elections/african-coup-and-conflict-tracker/",
                slug: "conflict-&-coups",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 100000050,
                name: "Democracy Level",
                destination: "/open-data/elections/african-democracy-level/",
                slug: "democracy-level",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 100000060,
                name: "Age of Democracy",
                destination: "/open-data/elections/age-of-democracy-in-africa/",
                slug: "age-of-democracy",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 100000070,
                name: "Term Compliance",
                destination:
                  "/open-data/elections/term-compliance-african-leaders/",
                slug: "term-compliance",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 100000080,
                name: "Election Integrity",
                destination: "/open-data/elections/african-election-integrity/",
                slug: "election-integrity",
                isFilterItem: false,
                isSidebarItem: true
              }
            ]
          },
          {
            id: 10000060,
            name: "Country Data & History",
            destination: "#",
            slug: "country-data-history",
            isFilterItem: false,
            isSidebarItem: true,
            children: [
              {
                id: 100000090,
                name: "Botswana",
                destination: "/open-data/elections/botswana-elections/",
                slug: "botswana",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000100,
                name: "Chad",
                destination: "/open-data/elections/chad-elections/",
                slug: "chad",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000110,
                name: "Egypt",
                destination: "/open-data/elections/egypt-elections/",
                slug: "egypt",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000120,
                name: "Ghana",
                destination: "/open-data/elections/ghana-elections/",
                slug: "ghana",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000130,
                name: "Guinea",
                destination: "/open-data/elections/guinea-elections/",
                slug: "guinea",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000140,
                name: "Guinea-Bissau",
                destination: "/open-data/elections/guinea-bissau-elections/",
                slug: "guinea-bissau",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000150,
                name: "Kenya",
                destination: "/open-data/elections/kenya-elections/",
                slug: "kenya",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000160,
                name: "Mauritania",
                destination: "/open-data/elections/mauritania-elections/",
                slug: "mauritania",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000170,
                name: "Namibia",
                destination: "/open-data/elections/namibia-elections/",
                slug: "namibia",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000180,
                name: "Nigeria",
                destination: "/open-data/elections/nigeria-elections/",
                slug: "nigeria",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000190,
                name: "Senegal",
                destination: "/open-data/elections/senegal-elections/",
                slug: "senegal",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000200,
                name: "South Africa",
                destination: "/open-data/elections/south-africa-elections/",
                slug: "south-africa",
                isFilterItem: false,
                isSidebarItem: true
              },
              {
                id: 1000000210,
                name: "Tunisia",
                destination: "/open-data/elections/tunisia-elections/",
                slug: "tunisia",
                isFilterItem: false,
                isSidebarItem: true
              }
            ]
          },
          {
            id: 10000080,
            name: "Directory of Local Resources",
            destination: "/open-data/elections/directory/",
            slug: "directory-of-local-resources",
            isFilterItem: false,
            isSidebarItem: true,
            children: []
          }
        ]
      }
    ]
  }
}

export default SidebarMenuItemsMockV2
